import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Program, getDateAsString, getStringAsDate } from './Program';
import { CalendarEvent } from './CalendarEvent';
import { IProgram } from './IProgram';
import { colors } from './colors';
import { ProgramExercise } from './ProgramExercise';
import { IExercise } from './IExercise';
import { IProgramExercise } from './IProgramExercise';
import { AssignedDate } from './AssignedDate';

@Injectable({
  providedIn: 'root',
})
export class ProgramManagerService {
  getEditedProgram() {
    const currentTime = new Date();
    currentTime.setHours(0, 0, 0, 0);
    const program = this.getSelectedProgram();
    const exercises = program.exercises;
    const future_events = this._all_events.filter(
      (e) =>
        e.start.getTime() >= currentTime.getTime() &&
        e.meta?.program_id == this._selected_program_id
    );

    for (const ex of exercises) {
      //keep old dates or performed ones
      ex.assigned_dates = ex.assigned_dates.filter(
        (ad) => ad.date.getTime() < currentTime.getTime() || ad.performed
      );
      const next_dates: string[] = future_events.reduce((p, c) => {
        p[getDateAsString(c.start)] = false;
        return p;
      }, {}) as string[];
      //add the future dates
      if (!ex.deleted) {
        for (const ed of Object.keys(next_dates)) {
          const eventStartDate = getStringAsDate(ed);
          const exists = this._checkIfEventExistsOnOtherPrograms(
            eventStartDate,
            ex.exercise_id
          );
          
          if (!exists) {
            ex.assigned_dates.push(
              new AssignedDate({ performed: false, date: ed })
            );
          }
        }
      }
    }
    return {
      program_id: this._selected_program_id,
      exercises,
    };
  }
  private _checkIfEventExistsOnOtherPrograms(date: Date, exercise_id: string) {
    return (
      this._all_events.filter((e) => {
        return (
          e.meta?.program_id != this._selected_program_id &&
          e.meta?.exercise_id == exercise_id &&
          e.start.getTime() == date.getTime()
        );
      })?.length != 0
    );
  }
  private _programs: Program[] = [];
  private _selected_program_id: string;
  programs$: BehaviorSubject<Program[]> = new BehaviorSubject<Program[]>([]);
  private _all_events: CalendarEvent[] = [];
  all_events$: BehaviorSubject<CalendarEvent[]> = new BehaviorSubject<
    CalendarEvent[]
  >([]);
  private lang: string = 'en';
  get programs() {
    return this._programs;
  }
  constructor() {}
  setLanguage(locale: string) {
    this.lang = locale;
    this.renameEvents();
  }
  getProgramById(programId: string) {
    const index = this._programs.findIndex((p) => p.program_id == programId);
    return this._programs[index];
  }
  onCancelEditing() {
    const index = this._programs.findIndex(
      (p) => p.program_id == this._selected_program_id
    );
    if (index > -1) {
      this._programs[index] = this.orginal_copy.deepClone();
      this._programs[index]._generateEvents();
      this._all_events = this._all_events.filter(
        (e) => e.meta?.program_id != this._selected_program_id
      );
      this._all_events.push(...this._programs[index].getEvents());
      this.__updateEventsList('u1');
      
    }
  }
  private __updateEventsList(src: string) {
    this.all_events$.next([...this._all_events]);
  }
  toggleClickedDateEvents(clickedDate: Date) {
    const currentTime = new Date();
    currentTime.setHours(0, 0, 0, 0);
    clickedDate.setHours(0, 0, 0, 0);
    if (clickedDate.getTime() < currentTime.getTime()) return;

    const clicked_date_events = this._all_events.filter((e) => {
      return (
        e.start.getTime() == clickedDate.getTime() &&
        e.meta?.program_id == this._selected_program_id
      );
    });
    if (clicked_date_events?.length > 0) {
      //remove the list from this date
      this.getSelectedProgram().removeDayEvents(clickedDate);
      this._all_events = this._all_events.filter(
        (e) => e.meta?.program_id != this._selected_program_id
      );
      this.getSelectedProgram()?._events && this._all_events.push(...this.getSelectedProgram()?._events);
      this.__updateEventsList('u2');
    } else {
      //add the list to this date
      const el = this.getSelectedProgram().getExercisesList();
      if (el?.length > 0) {
        for (const exercise of el) {
          const pe = new ProgramExercise({
            ...exercise,
            exercise_id: exercise.exercise_id,
            //program_id: exercise.exercise_id,
          });
          if (!this._checkIfEventAlreadyExists(pe.exercise_id, clickedDate)) {
            this.getSelectedProgram().addEvent(pe, clickedDate);
          }
        }
        this._all_events = this._all_events.filter(
          (e) => e.meta?.program_id != this._selected_program_id
        );
        this.getSelectedProgram()?._events && this._all_events.push(...this.getSelectedProgram()?._events);
        this.__updateEventsList('u3');
      }
    }
  }
  private _checkIfEventAlreadyExists(exercise_id: string, date: Date): boolean {
    const i = this._all_events.findIndex(
      (e) =>
        e.meta?.exercise_id == exercise_id &&
        e.start.getTime() == date.getTime()
    );
    return i > -1;
  }
  getCurrentProgramExercises(): IProgramExercise[] {
    return this.getSelectedProgram()?.exercises;
  }

  getSelectedProgramExercises() {}
  removeExercise(exerciseId: string) {
    this.getSelectedProgram()?.deleteExercise(exerciseId);
    this._all_events = this._all_events.filter(
      (e) => e.meta?.program_id != this._selected_program_id
    );
    this._all_events.push(...this.getSelectedProgram().getEvents());
    this.__updateEventsList('u4');
  }
  addExercise(selectedExercise: any) {
    this._all_events = this._all_events.filter(
      (e) => e.meta?.program_id != this._selected_program_id
    );
    
    this.getSelectedProgram()?.addExercise(selectedExercise,this._all_events);
    this._all_events.push(...this.getSelectedProgram().getEvents());
    this.__updateEventsList('u5');
  }
  getSelectedProgram(): Program | undefined {
    if (this._selected_program_id?.length > 0)
      return this.getProgramById(this._selected_program_id);
    return null;
  }
  
  getAllEvents(): CalendarEvent[] {
    
    return this._all_events;
  }

  renameEvents() {
    this._all_events.splice(0, this._all_events.length);
    this._programs.forEach((program) => {
      program.setLanguage(this.lang);
      program._generateEvents();
      this._all_events.push(...program._events);
    })
    this.__updateEventsList('u11');
  }

  setPrograms = (programs: IProgram[]) => {
    this._programs = [];
    this._all_events.splice(0, this._all_events.length);
    programs.forEach((p) => {
      const pro = new Program(p);
      pro.setLanguage(this.lang);
      pro._generateEvents();
      this._programs.push(pro);
      this._all_events.push(...pro._events);
    });
    this.programs$.next([...this._programs]);
    this.__updateEventsList('u6');
  };
  addProgram = (program: IProgram): void => {
    const pro = new Program(program);
    const newList = [pro];
    if (this.programs.length > 0) newList.push(...this.programs);
    this.programs$.next(newList);
    this._programs = newList;
    this._all_events.push(...pro._events);
    this.__updateEventsList('u7');
  };
  updateProgram = (program: Program): void => {
    const index = this._programs.findIndex(
      (p) => p.program_id == program.program_id
    );
    if (index > -1) {
      this._programs.splice(index, 1, program);
    }
    this.programs$.next([...this.programs]);
    this._all_events = this._all_events.filter(
      (e) => e.meta?.program_id != program.getProgramId()
    );
    this._all_events.push(...program._events);
    this.__updateEventsList('u8');
  };
  deleteProgram = (programId: string): void => {
    this._programs = this._programs?.filter((p) => p.program_id != programId);
    this.programs$.next([...this._programs]);
    this._all_events = this._all_events.filter(
      (e) => e.meta?.program_id != programId
    );
    this.__updateEventsList('u9');
  };
  orginal_copy: Program;
  highLightProgramEvents() {
    const currentTime = new Date();
    currentTime.setHours(0, 0, 0, 0);
    this._all_events = this._all_events.map((e) => {
      if (e.meta?.program_id == this._selected_program_id) {
        if (e.start.getTime() >= currentTime.getTime() && !e.meta?.performed) {
          e.color = colors.blue;
        }
      } else {
        e.color = colors.yellow;
        if (e.start.getTime() <= currentTime.getTime()) {
          if (e.meta?.performed) {
            //performed = true;
            e.color = colors.green;
          } else {
            //performed = false;
            if (e.start.getTime() < currentTime.getTime()) {
              e.color = colors.red;
            }
          }
        }
      }
      return e;
    });
    this.__updateEventsList('u10');
  }
  setSelectedProgram(programId: string) {
    // if (this._selected_program_id) {
    //   this.onCancelEditing();
    // }
    this._selected_program_id = programId;
    this.orginal_copy = this.getSelectedProgram().deepClone();
  }
  onExerciseDone = (exercise: IExercise): void => {};
  
}
