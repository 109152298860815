<div class="container">
  <section class="slice slice-lg pt-3">

    <div class="row justify-content-center">
      <div class="col-12">
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <a *ngIf="exerciseSelection" class="btn btn-transparent btn-icon py-0 pl-0"
              (click)="onBackButton(cancelModal)">
              <i class="fas fa-arrow-left"></i>
              {{ "patientDetails.back" | translate }}
            </a>
            <a *ngIf="programCreation" class="btn btn-transparent btn-icon py-0 pl-0"
              (click)="backToExerciseSelection(calendarComponent)">
              <i class="fas fa-arrow-left"></i>
              {{ "patientDetails.back" | translate }}
            </a>
          </div>
          <div style="margin-left: auto;">
            <button type="button" class="btn btn-warning btn-circle btn-sm mr-2" (click)="openModal(InformationModal);">
              <i class="fas fa-question" style="color: white"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-8 col-12">
        <div *ngIf="exerciseSelection">
          <div class="row col-12 my-3 align-items-center">
            <h2>{{ "createExerciseProgram.templateAssign" | translate }}</h2>
            <i style="font-size: 2.4rem; color: #152C5B; cursor: pointer; transition: transform 0.3s;"
              class="fa fa-angle-up ml-3 mb-2" [ngClass]="{'fa-flip-vertical': !templatesOpen}" aria-hidden="true"
              (click)="toggleTemplates()">
            </i>
          </div>
          <div *ngIf="templatesOpen" class="mb-3">
            <div *ngFor="let template of extendedTemplates; index as i">
              <div class="card py-3 pl-2 pr-3 mb-3" [ngClass]="{'card-border-edited': template.edited}"
                [ngStyle]="{'cursor':template.expanded ? 'default' : 'pointer'}" (click)="template.expanded = true">
                <div class="w-100 row mx-0" [ngClass]="{'mb-3': template.expanded}">
                  <i style="font-size: 2.3rem; color: #152C5B; cursor: pointer; transition: transform 0.3s"
                    class="fa fa-angle-up mr-3 ml-2 mb-1" [ngClass]="{'fa-flip-vertical': !template.expanded}"
                    aria-hidden="true" (click)="toggleTemplate(template);$event.stopPropagation()">
                  </i>
                  <input type="text" class="form-control w-75 py-0 px-2 font-weight-bold text-xl"
                    style="height: 2.6rem;" :placeholder="{{ 'createExerciseProgram.templates.title' | translate }}"
                    [ngStyle]="{'cursor':template.expanded ? 'default' : 'pointer'}"
                    [(ngModel)]="template.template_name" [disabled]="!template.expanded"
                    (input)="template.edited = true" />
                  <div class="my-auto h-100 d-flex align-items-center justify-content-end flex-row ml-auto"
                    style="width: 17%; gap: 0.5rem;">
                    <i *ngIf="template.expanded && template.edited && template.template_name && template.exercise_program?.length"
                      class="fas fa-save" style="font-size: 1.8rem; color: #9AB6D9; cursor: pointer"
                      (click)="saveTemplate(template, i); $event.stopPropagation()"></i>
                    <i *ngIf="template.expanded && template.edited && template._id" class="fas fa-undo"
                      style="font-size: 1.6rem; color: #9F7181; cursor: pointer"
                      (click)="revertTemplate(i); $event.stopPropagation()"></i>
                    <i *ngIf="template.expanded && !(template.edited && template._id)" class="fas fa-trash"
                      style="font-size: 1.6rem; color: #ffacac; cursor: pointer"
                      (click)="openModal(deleteTemplateModal); templateDeletionIndex = i; $event.stopPropagation()"></i>
                    <i class="fas fa-chevron-circle-right ml-1"
                      style="font-size: 2.3rem; color: #152c5b; cursor: pointer"
                      (click)="selectTemplate(template); $event.stopPropagation()"></i>
                  </div>
                </div>
                <div *ngIf="template.expanded && template.exercise_program?.length" class="w-100 row mx-0 mb-3">
                  <div class="w-100 px-3">
                    <div *ngFor="let exercise of template.exercise_program; index as j">
                      <div class="row align-items-center mx-0 rounded bg-gray-100 mb-2">
                        <div class="d-flex align-items-center justify-content-center ml-2" style="height: 5rem;">
                          <i class="fas fa-minus-circle" style="font-size: 25px; color: #7b3939; cursor: pointer"
                            (click)="removeTemplateExercise(template, j)"></i>
                        </div>
                        <div class="col-lg-2 col-md-2 d-flex align-items-center justify-content-center"
                          style="height: 5rem;">
                          <img alt="Image placeholder" [src]="getGifLinks(exercise.name)"
                            (error)="getExerciseGif($event, exercise)" style="height: 50px" />
                        </div>
                        <div class="col-lg-4 col-md-10 my-au pr-1 align-items-center">
                          <h5 class="lh-120 my-0" style="font-size: 1.1rem;">
                            {{ exercise.display_name[language] ?? exercise.display_name['en'] }}
                          </h5>
                          <p class="text-muted my-0" style="font-size: 0.9rem;">
                            {{ "patientDetails.category" | translate }}:
                            {{ "generalTerms." + (['breathing_time', 'relaxation'].includes(exercise.category) ? 'breathing' :
                            exercise.category) | translate }}
                          </p>
                          <div class="d-flex align-items-center mt-1" style="gap: 0.5rem">
                            <p class="mb-0" style="margin-top: 0.2rem">{{ "generalTerms.aiTrainer" | translate }}</p>  
                            <ng-toggle
                              [(ngModel)]="exercise.initial_pose.check"
                              [color]="{
                                unchecked: '#939da2',
                                checked: '#33a1ff'
                              }"
                            ></ng-toggle>
                          </div>
                        </div>
                        <div class="col-lg-5 col-md-12 mx-auto pt-2">
                          <div *ngIf="exercise.hasOwnProperty('sets')" class="row justify-content-center">
                            <div class="exercise-input col-lg-4 col-md-4 form-group">
                              <label for="input-sets" class="font-weight-bold">{{
                                "createExerciseProgram.sets" | translate
                                }}</label>
                              <input (click)="onInnerDivClick($event)" type="number"
                                class="form-control text-center py-0"
                                style="height: 2rem; width: 4rem; padding-left: 0.4rem; padding-right: 0.1rem;"
                                id="input-sets" name="input-sets" placeholder="1" min="1" max="10"
                                [disabled]="exercise.name === 'sit2stand_test' || ['breathing_time', 'relaxation'].includes(exercise.category)"
                                [(ngModel)]="exercise.sets" (input)="template.edited = true"/>
                            </div>
                            <div *ngIf="!['breathing_time', 'relaxation'].includes(exercise.category)" class="exercise-input col-lg-4 col-md-4 form-group">
                              <label for="input-break" class="font-weight-bold">{{
                                "createExerciseProgram.break" | translate
                                }}(s)</label>
                              <input (click)="onInnerDivClick($event)" type="number"
                                class="form-control text-center py-0"
                                style="height: 2rem; width: 4rem; padding-left: 0.4rem; padding-right: 0.1rem;"
                                id="input-break" name="input-break" placeholder="25" min="25"
                                [(ngModel)]="exercise.set_break.duration" (input)="template.edited = true"/>
                            </div>
                            <div *ngIf="exercise.category === 'breathing' && exercise.actions.length > 2" class="exercise-input col-lg-4 col-md-4">
                              <div class="form-group">
                                <label for="input-repetitions" class="font-weight-bold mb-0">
                                  {{ "createExerciseProgram.repetitions" | translate }}
                                </label>
                                <input (click)="onInnerDivClick($event)" type="number"
                                  class="form-control text-center py-0"
                                  style="height: 2rem; width: 4rem; padding-left: 0.4rem; padding-right: 0.1rem;"
                                  id="input-repetitions" name="input-repetitions" placeholder="1" min="1" max="10"
                                  [disabled]="['breathing_time', 'relaxation'].includes(exercise.category)"
                                  [(ngModel)]="exercise.repetitions" (input)="template.edited = true" />
                              </div>
                            </div>
                          </div>
                          <div class="row justify-content-center">
                            <div *ngIf="!['sit2stand_test', 'sit2stand_duration', 'sit2stand_counter', 'squat'].includes(exercise.name) && exercise.category !== 'breathing' && exercise.actions.length < 3" class="exercise-input col-lg-4 col-md-4">
                              <div class="form-group">
                                <label for="input-repetitions" class="font-weight-bold mb-0">
                                  {{ "createExerciseProgram.repetitions" | translate }}
                                </label>
                                <input (click)="onInnerDivClick($event)" type="number"
                                  class="form-control text-center py-0"
                                  style="height: 2rem; width: 4rem; padding-left: 0.4rem; padding-right: 0.1rem;"
                                  id="input-repetitions" name="input-repetitions" placeholder="1" min="1" max="10"
                                  [disabled]="['breathing_time', 'relaxation'].includes(exercise.category)"
                                  [(ngModel)]="exercise.repetitions" (input)="template.edited = true" />
                              </div>
                            </div>
                            <div *ngIf="exercise.category === 'lower_body'"
                              class="exercise-input col-lg-4 col-md-4 d-flex">
                              <div *ngIf="exercise.actions[0] | hasProp : 'duration'" class="form-group">
                                <label for="input-duration" class="font-weight-bold mb-0">
                                  {{ "createExerciseProgram.duration" | translate }}(s)
                                </label>
                                <input (click)="onInnerDivClick($event)" type="number"
                                  class="form-control text-center py-0"
                                  style="height: 2rem; width: 4rem; padding-left: 0.4rem; padding-right: 0.1rem;"
                                  id="input-duration" name="input-duration" placeholder="10" min="1"
                                  [(ngModel)]="exercise.actions[0].duration"
                                  [disabled]="exercise.name === 'sit2stand_test'" (input)="template.edited = true" />
                              </div>
                              <div *ngIf="exercise.actions[0] | hasProp : 'count'" class="form-group">
                                <label for="input-counter" class="font-weight-bold mb-0">
                                  {{ "exercise.count" | translate }}
                                </label>
                                <input (click)="onInnerDivClick($event)" type="number"
                                  class="form-control text-center py-0"
                                  style="height: 2rem; width: 4rem; padding-left: 0.4rem; padding-right: 0.1rem;"
                                  id="input-counter" name="input-counter" placeholder="10" min="1" max="30"
                                  [(ngModel)]="exercise.actions[0].count" (input)="template.edited = true" />
                              </div>
                            </div>
                            <div *ngIf="exercise.category === 'stretch' && exercise.actions[0] | hasProp : 'count'"
                              class="exercise-input form-group col-lg-4 col-md-4">
                              <label for="input-side-count" class="font-weight-bold mb-0">
                                {{ "exercise.count" | translate }}
                              </label>
                              <input (click)="onInnerDivClick($event)" type="number"
                                class="form-control text-center py-0"
                                style="height: 2rem; width: 4rem; padding-left: 0.4rem; padding-right: 0.1rem;"
                                id="input-side-count" name="input-side-count" placeholder="10" min="1"
                                [(ngModel)]="exercise.actions[0].count"
                                (input)="exercise.actions[1].count = exercise.actions[0].count; template.edited = true" />
                            </div>
                            <!-- <div
                              *ngIf="
                                exercise.category === 'stretch' &&
                                exercise.actions[1] &&
                                (exercise.actions[1] | hasProp : 'duration')
                              "
                              class="col-lg-4 col-md-4"
                              style="height: 5rem;"
                            >
                              <div class="form-group">
                                <label for="input-break-duration" class="font-weight-bold mb-0">
                                  {{ "createExerciseProgram.break" | translate }}(s)
                                </label>
                                <input (click)="onInnerDivClick($event)"
                                  type="number"
                                  class="form-control text-center py-0"
                                  style="height: 2rem; padding-left: 0.4rem; padding-right: 0.1rem;"
                                  id="input-break-duration"
                                  name="input-break-duration"
                                  placeholder="10"
                                  min="1"
                                  [(ngModel)]="exercise.actions[1].duration"
                                  (input)="onChangingStretchDuration(exercise); template.edited = true"
                                />
                              </div>
                            </div> -->
                            <div *ngIf="exercise.category === 'breathing'" class="exercise-input col-lg-4 col-md-4">
                              <div class="form-group">
                                <label for="input-inhale" class="font-weight-bold text-center mb-0"
                                  style="white-space: nowrap">
                                  {{ "createExerciseProgram.inhale" | translate }}(s)
                                </label>
                                <input (click)="onInnerDivClick($event)" type="number"
                                  class="form-control text-center py-0"
                                  style="height: 2rem; width: 4rem; padding-left: 0.4rem; padding-right: 0.1rem;"
                                  id="input-inhale" name="input-inhale" placeholder="1" min="1" max="10"
                                  [(ngModel)]="exercise.actions[0].duration" (input)="template.edited = true" />
                              </div>
                            </div>
                            <div *ngIf="exercise.category === 'breathing' && exercise.actions.length > 2" class="exercise-input col-lg-4 col-md-4">
                              <div class="form-group">
                                <label for="input-hold" class="font-weight-bold text-center mb-0"
                                  style="white-space: nowrap">
                                  {{ "createExerciseProgram.hold" | translate }}(s)
                                </label>
                                <input (click)="onInnerDivClick($event)" type="number"
                                  (change)="updateExerciseHoldDuration($event, exercise)"
                                  class="form-control text-center py-0"
                                  style="height: 2rem; width: 4rem; padding-left: 0.4rem; padding-right: 0.1rem;"
                                  id="input-hold" name="input-hold" placeholder="0" min="0" max="10"
                                  [(ngModel)]="exercise.actions[1].duration" (input)="template.edited = true" />
                              </div>
                            </div>
                            <div *ngIf="exercise.category === 'breathing'" class="exercise-input col-lg-4 col-md-4">
                              <div class="form-group">
                                <label for="input-exhale" class="font-weight-bold text-center mb-0"
                                  style="white-space: nowrap">
                                  {{ "createExerciseProgram.exhale" | translate }}(s)
                                </label>
                                <input (click)="onInnerDivClick($event)" type="number"
                                  class="form-control text-center py-0"
                                  style="height: 2rem; width: 4rem; padding-left: 0.4rem; padding-right: 0.1rem;"
                                  id="input-exhale" name="input-exhale" placeholder="1" min="1" max="10"
                                  [(ngModel)]="exercise.actions[exercise.actions.length > 3 ? 2 : exercise.actions.length - 1].duration" (input)="template.edited = true" />
                              </div>
                            </div>
                            <div *ngIf="exercise.category === 'breathing_time'" class="col-lg-4 col-md-4"
                              style="height: 5rem;">
                              <div class="form-group">
                                <label for="input-breathing" class="font-weight-bold text-center mb-0"
                                  style="white-space: nowrap">
                                  {{ "createExerciseProgram.duration" | translate }}(s)
                                </label>
                                <input (click)="onInnerDivClick($event)" type="number"
                                  class="form-control text-center py-0"
                                  style="height: 2rem; padding-left: 0.4rem; padding-right: 0.1rem;"
                                  id="input-breathing" name="input-breathing" placeholder="15" min="15"
                                  [(ngModel)]="exercise.actions[1].duration" (input)="template.edited = true" />
                              </div>
                            </div>
                            <div *ngIf="exercise.category === 'relaxation'" class="col-lg-4 col-md-4"
                              style="height: 5rem;">
                              <div class="form-group">
                                <label for="input-breathing" class="font-weight-bold text-center mb-0"
                                  style="white-space: nowrap">
                                  {{ "createExerciseProgram.duration" | translate }}(s)
                                </label>
                                <input (click)="onInnerDivClick($event)" type="number"
                                  class="form-control text-center py-0"
                                  style="height: 2rem; padding-left: 0.4rem; padding-right: 0.1rem;"
                                  id="input-breathing" name="input-breathing" placeholder="15" min="15"
                                  [(ngModel)]="exercise.actions[0].duration" (input)="template.edited = true" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="template.expanded" ngbDropdown class="w-full px-3" (click)="refreshSelectedTemplateExercisesList(template);$event.stopPropagation()">
                  <div class="exercise-options d-flex align-items-center justify-content-center w-full">
                    <i class="fas fa-plus-circle" aria-hidden="true"
                      style="font-size: 30px; color: #2fc19a; cursor: pointer" id="dropdownAddToTemplate"
                      ngbDropdownToggle></i>
                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownAddToTemplate" style="width: max-content;">
                    <div class="d-flex align-items-center px-2" style="width: 100%;">
                      <p class="my-auto">{{ "generalTerms.search" | translate }}:</p>
                      <input type="text" class="form-control mx-2 my-1" id="search-text-template"
                        aria-describedby="search-text-template" style="height: 2.3rem;"
                        [(ngModel)]="searchExerciseTemplate" placeholder="{{ 'generalTerms.search' | translate }}" />
                    </div>
                    <div class="w-100 d-flex flex-column align-items-center"
                      style="max-height: 25rem; height: fit-content; width: max-content; overflow-y: auto;">
                      <ng-directive *ngIf="selected_template_available_exercises
                      | searchFilter
                        : 'exercise'
                        : searchExerciseTemplate
                        : 'none' as filteredExerciseItems">
                        <div *ngFor="let exerciseOption of filteredExerciseItems">
                          <div *ngIf="!programHasExercise(template.exercise_program, exerciseOption)"
                            class="row mx-0 align-items-center"
                            style="display: flex; width: fit-content; flex-wrap: nowrap; cursor: pointer;"
                            (click)="addTemplateExercise(template, exerciseOption)" ngbDropdownItem>
                            <div class="d-flex px-0" style="height: 3.5rem; width: 4rem;">
                              <img alt="Image placeholder" [src]="getGifLinks(exerciseOption.name)"
                                (error)="getExerciseGif($event, exerciseOption)" class="w-100 h-100 m-0" />
                            </div>
                            <div class="d-flex px-0 ml-2" style="color: inherit">
                              <h6 class="lh-100 my-0 d-inline" style="font-size: 0.9rem; color: inherit;">
                                {{ exerciseOption.display_name[language] ?? exerciseOption.display_name['en'] }}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="(filteredExerciseItems).length===0">
                          <p class="font-italic text-center mt-3">
                            {{ "exercise.exerciseNotFound" | translate }}
                          </p>
                        </div>
                      </ng-directive>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-center w-full rounded"
              style="height: 2.5rem; background-color: #2fc19a; cursor: pointer" (click)="addTemplate()">
              <i class="fas fa-plus-circle" aria-hidden="true" style="font-size: 25px; color: white"></i>
              <h3 style="font-size: 25px; color: white" class="mb-0 ml-3">
                {{ "createExerciseProgram.templates.createTemplate" | translate }}
              </h3>
            </div>
          </div>
          <hr>
          <div class="row col-12">
            <h2>{{ "createExerciseProgram.createNew" | translate }}</h2>
          </div>
          <div class="d-flex ml-5 mt-3 mb-3 align-items-center">
            <p class="my-auto">{{ "generalTerms.search" | translate }}:</p>
            <input type="email" class="form-control w-25 ml-2 my-auto" id="search-text" aria-describedby="search-text"
              style="height: 40px" [(ngModel)]="searchExerciseText"
              placeholder="{{ 'generalTerms.search' | translate }}" />
            <p class="pl-3 my-auto">{{ "generalTerms.filter" | translate }}:</p>

            <div ngbDropdown class="ml-2 my-auto">
              <button *ngIf="exerciseFilterSelected === 'none'" class="btn btn-xs btn-secondary" id="dropdownMenuButton"
                ngbDropdownToggle>
                {{ "generalTerms.select" | translate }}
              </button>
              <button *ngIf="exerciseFilterSelected !== 'none'" class="btn btn-xs btn-secondary" id="dropdownMenuButton"
                ngbDropdownToggle>
                {{ "generalTerms." + exerciseFilterSelected | translate }}
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
                <button ngbDropdownItem *ngFor="let option of exerciseFilterOptions"
                  (click)="exerciseFilterSelected = option">
                  {{ "generalTerms." + option | translate }}
                </button>
              </div>
            </div>
            <button *ngIf="exerciseFilterSelected !== 'none' || searchExerciseText" class="btn btn-xs btn-danger ml-3"
              (click)="searchExerciseText = ''; exerciseFilterSelected = 'none'">
              {{ "createExerciseProgram.removeFilters" | translate }}
            </button>
          </div>
          <ng-directive *ngIf="allExercises
            | searchFilter
              : 'exercise'
              : searchExerciseText
              : exerciseFilterSelected as filteredExerciseItems">
            <div *ngFor="
              let exercise of filteredExerciseItems
                | slice
                  : (exercisePage - 1) * exercisePageSize
                  : exercisePage * exercisePageSize;
                index as i
              ">
              <div class="card p-4" [ngClass]="{
                  'card-border-primary': isExerciseChosen(exercise)

                }" style="cursor:pointer;">
                <div class="w-100" (click)="chooseExercise(exercise)">

                  <span (click)="onInnerDivClick($event)">
                    <i *ngIf="!isExerciseChosen(exercise)" (click)="chooseExercise(exercise)" class="fas fa-plus-circle"
                      style="font-size: 20px; color: #2fc19a; cursor: pointer"></i>
                    <i *ngIf="isExerciseChosen(exercise)" (click)="chooseExercise(exercise)" class="fas fa-minus-circle"
                      style="font-size: 20px; color: #7b3939; cursor: pointer"></i>
                  </span>
                </div>
                <div class="row" (click)="chooseExercise(exercise)">
                  <div class="col-lg-2 col-md-2 d-flex align-items-center justify-content-center">
                    <img alt="Image placeholder" [src]="getGifLinks(exercise.name)"
                      (error)="getExerciseGif($event, exercise)" style="height: 80px" id="navbar-logo" />
                  </div>
                  <div class="col-lg-4 col-md-10 my-auto pr-1">
                    <h5 class="lh-130">
                      {{ exercise.display_name[language] ?? exercise.display_name['en'] }}
                    </h5>
                    <p class="text-muted mb-2">
                      {{ "patientDetails.category" | translate }}:
                      {{ "generalTerms." + (['breathing_time', 'relaxation'].includes(exercise.category) ? 'breathing' : exercise.category) |
                      translate }}
                    </p>
                    <div class="d-flex align-items-center mt-1" style="gap: 0.5rem">
                      <p class="mb-0" style="margin-top: 0.2rem">{{ "generalTerms.aiTrainer" | translate }}</p>  
                      <ng-toggle
                      [(ngModel)]="exercise.initial_pose.check"
                        [color]="{
                          unchecked: '#939da2',
                          checked: '#33a1ff'
                        }"
                      ></ng-toggle>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 flex-col mx-auto">
                    <div *ngIf="exercise.hasOwnProperty('sets')" class="exercise-input-row row justify-content-center">
                      <div class="exercise-input col-lg-4 col-md-4 form-group">
                        <label for="input-sets" class="font-weight-bold">{{
                          "createExerciseProgram.sets" | translate
                          }}</label>
                        <input (click)="onInnerDivClick($event)" type="number" class="form-control text-center py-0"
                          style="height: 2rem; width: 4rem; padding-left: 0.4rem; padding-right: 0.1rem;" id="input-sets"
                          name="input-sets" 
                          [disabled]="exercise.name === 'sit2stand_test' || ['breathing_time', 'relaxation'].includes(exercise.category)" 
                          placeholder="1" min="1" max="10" [(ngModel)]="exercise.sets"/>
                      </div>
                      <div *ngIf="!['breathing_time', 'relaxation'].includes(exercise.category)" class="exercise-input col-lg-4 col-md-4 form-group">
                        <label for="input-break" class="font-weight-bold">{{
                          "createExerciseProgram.break" | translate
                          }} (s)</label>
                        <input (click)="onInnerDivClick($event)" type="number" class="form-control text-center py-0"
                          style="height: 2rem; width: 4rem; padding-left: 0.4rem; padding-right: 0.1rem;" id="input-break"
                          name="input-break" 
                          [disabled]="exercise.name === 'sit2stand_test'" 
                          placeholder="25" min="25" [(ngModel)]="exercise.set_break.duration"/>
                      </div>
                      <div *ngIf="exercise.category === 'breathing'" class="exercise-input col-lg-4 col-md-4 form-group">
                        <label for="input-repetitions" class="font-weight-bold">{{
                          "createExerciseProgram.repetitions" | translate
                          }}</label>
                        <input (click)="onInnerDivClick($event)" type="number" class="form-control text-center py-0"
                          style="height: 2rem; width: 4rem; padding-left: 0.4rem; padding-right: 0.1rem;"
                          id="input-repetitions" name="input-repetitions" placeholder="1" min="1" max="10"
                          [disabled]="['breathing_time', 'relaxation'].includes(exercise.category)"
                          [(ngModel)]="exercise.repetitions" />
                      </div>
                    </div>
                    <div class="exercise-input-row row justify-content-center">
                      <!--              <div class="d-lg-none col-md-1"></div>-->
                      <div *ngIf="!['sit2stand_test', 'sit2stand_duration', 'sit2stand_counter', 'squat'].includes(exercise.name) && exercise.category !== 'breathing'" class="exercise-input col-lg-4 col-md-4 form-group">
                        <label for="input-repetitions" class="font-weight-bold">{{
                          "createExerciseProgram.repetitions" | translate
                          }}</label>
                        <input (click)="onInnerDivClick($event)" type="number" class="form-control text-center py-0"
                          style="height: 2rem; width: 4rem; padding-left: 0.4rem; padding-right: 0.1rem;"
                          id="input-repetitions" name="input-repetitions" placeholder="1" min="1" max="10"
                          [disabled]="['breathing_time', 'relaxation'].includes(exercise.category)"
                          [(ngModel)]="exercise.repetitions" />
                      </div>
                      <div *ngIf="exercise.category === 'lower_body'" class="exercise-input col-lg-4 col-md-4 d-flex">
                        <!--                          <div *ngIf="allExercisesCopy[i].actions[0].duration" class="form-group">-->
                        <div *ngIf="exercise.actions[0] | hasProp : 'duration'" class="form-group">
                          <label for="input-duration" class="font-weight-bold">{{
                            "createExerciseProgram.duration" | translate
                            }}
                            (s)</label>
                          <input (click)="onInnerDivClick($event)" type="number" class="form-control text-center py-0"
                            style="height: 2rem; width: 4rem; padding-left: 0.4rem; padding-right: 0.1rem;"
                            id="input-duration" name="input-duration" placeholder="10" min="1"
                            [(ngModel)]="exercise.actions[0].duration" [disabled]="exercise.name === 'sit2stand_test'" />
                        </div>
                        <!--                          <div *ngIf="allExercisesCopy[i].actions[0].count && exercise.actions[0].count" class="form-group">-->
                        <div *ngIf="exercise.actions[0] | hasProp : 'count'" class="form-group">
                          <label for="input-counter" class="font-weight-bold">{{
                            "exercise.count" | translate
                            }}</label>
                          <input (click)="onInnerDivClick($event)" type="number" class="form-control text-center py-0"
                            style="height: 2rem; width: 4rem; padding-left: 0.4rem; padding-right: 0.1rem;"
                            id="input-counter" name="input-counter" placeholder="10" min="1" max="30"
                            [(ngModel)]="exercise.actions[0].count" />
                        </div>
                      </div>
                      <div *ngIf="exercise.category === 'stretch' && exercise.actions[0] | hasProp : 'count'"
                        class="exercise-input col-lg-4 col-md-4 form-group">
                        <label for="input-side-count" class="font-weight-bold">{{
                          "exercise.count" | translate
                          }}</label>
                        <input (click)="onInnerDivClick($event)" type="number" class="form-control text-center py-0"
                          style="height: 2rem; width: 4rem; padding-left: 0.4rem; padding-right: 0.1rem;"
                          id="input-side-count" name="input-side-count" placeholder="10" min="1"
                          [(ngModel)]="exercise.actions[0].count" (change)="
                            exercise.actions[1].count = exercise.actions[0].count
                          " />
                      </div>
                      <!--                        <div *ngIf="exercise.category === 'stretch' && allExercisesCopy[i].actions.length > 1 && allExercisesCopy[i].actions[1].duration" class="col-lg-4 col-md-4">-->
                      <!-- <div
                        *ngIf="
                          exercise.category === 'stretch' &&
                          exercise.actions[1] &&
                          (exercise.actions[1] | hasProp : 'duration')
                        "
                        class="col-lg-4 col-md-4"
                      >
                        <div class="form-group">
                          <label for="input-break-duration" class="font-weight-bold"
                            >{{
                              "createExerciseProgram.break" | translate
                            }}
                            (s)</label
                          >
                          <input (click)="onInnerDivClick($event)"
                            type="number"
                            class="form-control text-center py-0"
                            style="height: 2rem; max-width: 4rem; padding-left: 0.4rem; padding-right: 0.1rem;"
                            id="input-break-duration"
                            name="input-break-duration"
                            placeholder="10"
                            min="1"
                            [(ngModel)]="exercise.actions[1].duration"
                            (change)="onChangingStretchDuration(exercise)"
                          />
                        </div>
                      </div> -->
                      <div *ngIf="exercise.category === 'breathing'" class="exercise-input col-lg-4 col-md-4">
                        <div class="form-group">
                          <label (click)="chooseExercise(exercise)" for="input-inhale"
                            class="font-weight-bold text-center" style="white-space: nowrap">{{
                            "createExerciseProgram.inhale" | translate
                            }}
                            (s)</label>
                          <input (click)="onInnerDivClick($event)" type="number" class="form-control text-center py-0"
                            style="height: 2rem; max-width: 4rem; padding-left: 0.4rem; padding-right: 0.1rem;"
                            id="input-inhale" name="input-inhale" placeholder="1" min="1" max="10"
                            [(ngModel)]="exercise.actions[0].duration" />
                        </div>
                      </div>
                      <div *ngIf="exercise.category === 'breathing'" class="exercise-input col-lg-4 col-md-4">
                        <div class="form-group">
                          <label (click)="chooseExercise(exercise)" for="input-hold"
                            class="font-weight-bold text-center" style="white-space: nowrap">{{
                            "createExerciseProgram.hold" | translate
                            }}
                            (s)</label>
                          <input (click)="onInnerDivClick($event)" type="number" class="form-control text-center py-0"
                            (change)="updateExerciseHoldDuration($event, exercise)" style="height: 2rem; max-width: 4rem; padding-left: 0.4rem; padding-right: 0.1rem;"
                            id="input-inhale" name="input-hold" placeholder="1" min="1" max="10"
                            [(ngModel)]="exercise.actions[1].duration" />
                        </div>
                      </div>
                      <div *ngIf="exercise.category === 'breathing'" class="exercise-input col-lg-4 col-md-4">
                        <div class="form-group">
                          <label (click)="chooseExercise(exercise)" for="input-exhale"
                            class="font-weight-bold text-center" style="white-space: nowrap">{{
                            "createExerciseProgram.exhale" | translate
                            }}
                            (s)</label>
                          <input (click)="onInnerDivClick($event)" type="number" class="form-control text-center py-0"
                            style="height: 2rem; max-width: 4rem; padding-left: 0.4rem; padding-right: 0.1rem;"
                            id="input-exhale" name="input-exhale" placeholder="1" min="1" max="10"
                            [(ngModel)]="exercise.actions[2].duration" />
                        </div>
                      </div>
                      <div *ngIf="exercise.category === 'breathing_time'" class="exercise-input col-lg-4 col-md-4 d-flex">
                        <div class="form-group">
                          <label (click)="chooseExercise(exercise)" for="input-breathing" class="font-weight-bold text-center"
                            style="white-space: nowrap">{{
                            "createExerciseProgram.duration" | translate
                            }}
                            (s)</label>
                          <input (click)="onInnerDivClick($event)" type="number"
                            style="height: 2rem; width: 4rem; padding-left: 0.4rem; padding-right: 0.1rem;" class="form-control text-center py-0"
                            id="input-breathing" name="input-breathing" placeholder="15" min="15" [(ngModel)]="exercise.actions[1].duration" />
                          </div>
                      </div>
                      <div *ngIf="exercise.category === 'relaxation'" class="exercise-input col-lg-4 col-md-4 d-flex">
                        <div class="form-group">
                          <label (click)="chooseExercise(exercise)" for="input-breathing" class="font-weight-bold text-center"
                            style="white-space: nowrap">{{
                            "createExerciseProgram.duration" | translate
                            }}
                            (s)</label>
                          <input (click)="onInnerDivClick($event)" type="number"
                            style="height: 2rem; width: 4rem; padding-left: 0.4rem; padding-right: 0.1rem;" class="form-control text-center py-0"
                            id="input-breathing" name="input-breathing" placeholder="15" min="15" [(ngModel)]="exercise.actions[0].duration" />
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="(filteredExerciseItems).length===0">
              <p class="font-italic text-center text-lg my-6">
                {{ "exercise.exerciseNotFound" | translate }}
              </p>
            </div>
          </ng-directive>
          <div class="d-flex mt-2 mb-2">
            <div class="mx-auto">
              <ngb-pagination [(page)]="exercisePage" [pageSize]="exercisePageSize" [collectionSize]="
                  (
                    allExercises
                    | searchFilter
                      : 'exercise'
                      : searchExerciseText
                      : exerciseFilterSelected
                  )?.length
                "></ngb-pagination>
            </div>
            <span class="float-right">
              <button class="btn btn-sm btn-primary" [disabled]="chosenExercises.length === 0" (click)="next()">
                {{ "generalTerms.next" | translate }}
              </button>
            </span>
          </div>
        </div>
        <div *ngIf="programCreation">
          <div class="mt-3 mb-3">
            <app-calendar [functionality]="'program-creation'" [exerciseList]="chosenExercises"
              [assignedEvents]="assignedExercises" (storeEvents)="storeTemporaryExercises($event)"
              #calendarComponent></app-calendar>
          </div>
          <div class="mt-3">
            <!--          <button class="btn btn-sm btn-secondary float-left" (click)="programCreation = false; exerciseSelection = true;">Back</button>-->
            <button class="btn btn-sm btn-primary float-right" [disabled]="
                selectedDatesEmpty(calendarComponent) || programCreated
              " (click)="createProgram(calendarComponent)">
              {{ "createExerciseProgram.create" | translate }}
            </button>
            <button class="btn btn-sm btn-danger float-left" [disabled]="selectedDatesEmpty(calendarComponent)" (click)="
                openModal(clearSelectedExercisesModal, calendarComponent)
              ">
              {{ "createExerciseProgram.clear" | translate }}
            </button>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4" style="position: relative">
        <div class="col-lg-4 col-md-4" style="position: fixed">
          <div class="mt-3 mb-3 pl-4">
            <h3>{{ "createExerciseProgram.program" | translate }}</h3>
          </div>
          <div *ngIf="chosenExercises.length === 0">
            <p class="font-italic pl-4">
              {{ "createExerciseProgram.noExercisesChosen" | translate }}
            </p>
          </div>
          <div cdkDropList *ngIf="chosenExercises.length > 0" (cdkDropListDropped)="drop($event)">
            <div *ngFor="
                let exercise of chosenExercises
                  | slice
                    : (chosenExercisesPage - 1) * chosenExercisesPageSize
                    : chosenExercisesPage * chosenExercisesPageSize;
                let i = index
              " class="card p-3" style="cursor: pointer" cdkDrag>
              <!--            <div class="h5 my-auto"><span><i class="fas fa-dot-circle"></i> {{exercise.name}}</span></div>-->
              <div class="h5 my-auto">
                {{ exercise.display_name[language] ?? exercise.display_name['en'] }}
              </div>
            </div>
          </div>
          <div *ngIf="chosenExercises.length > 4" class="d-flex mt-2 mb-2">
            <div class="mx-auto">
              <ngb-pagination [(page)]="chosenExercisesPage" [pageSize]="chosenExercisesPageSize"
                [collectionSize]="chosenExercises.length"></ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--Cancel modal-->

  <ng-template #cancelModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title">
        {{ "createExerciseProgram.cancelExerciseProgram.title" | translate }}
      </h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        {{ "createExerciseProgram.cancelExerciseProgram.question" | translate }}
      </p>
      <p>
        {{ "createExerciseProgram.cancelExerciseProgram.text1" | translate }}
        <span class="font-italic text-danger">{{
          "generalTerms.yes" | translate
          }}</span>{{ "createExerciseProgram.cancelExerciseProgram.text2" | translate }}
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-sm btn-danger" (click)="modal.close(); closeProgramCreation()">
        {{ "generalTerms.yes" | translate }}
      </button>
      <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close()">
        {{ "generalTerms.no" | translate }}
      </button>
    </div>
  </ng-template>

  <!--Delete Template modal-->

  <ng-template #deleteTemplateModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title">
        {{ "createExerciseProgram.templates.deleteModalTitle" | translate }}
      </h5>
      <button type="button" class="close" aria-label="Close"
        (click)="modal.dismiss('Cross click'); templateDeletionIndex = -1;">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        {{ "createExerciseProgram.templates.deleteModalText" | translate }}
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-sm btn-danger"
        (click)="modal.close(); deleteTemplate(templateDeletionIndex)">
        {{ "generalTerms.yes" | translate }}
      </button>
      <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close(); templateDeletionIndex = -1;">
        {{ "generalTerms.no" | translate }}
      </button>
    </div>
  </ng-template>

  <!--Clear selected exercises modal-->

  <ng-template #clearSelectedExercisesModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title">{{ "createExerciseProgram.clearDates" | translate }}</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ "createExerciseProgram.clearDatesQuestion" | translate }}</p>
      <!--    <p>If you click <span class="font-italic text-danger">Yes</span>, all your previous work will be lost!</p>-->
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-sm btn-danger" (click)="modal.close(); clearDates()">
        {{ "generalTerms.yes" | translate }}
      </button>
      <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close()">
        {{ "generalTerms.no" | translate }}
      </button>
    </div>
  </ng-template>
</div>
<ng-template #InformationModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title"><img class="mr-2" alt="Image placeholder"
        src="../../../assets/Logo/Breathment Logo_rev_white.jpg" style="height: 50px;" id="navbar-mobilr-logo"></h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{'tutorial.createExerciseProgram1' | translate}} </p>
    <p>{{'tutorial.createExerciseProgram2' | translate}} </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close();">{{ 'generalTerms.close' | translate
      }}</button>
  </div>
</ng-template>
