import { IAssignedDate } from './IAssignedDate';

export class AssignedDate implements IAssignedDate {
  date!: Date;
  performed!: boolean;
  constructor({
    date, performed,
  }: {
    date: string | Date;
    performed: boolean;
  }) {
    //DD.MM.YYYY
    try {
      if (typeof date === 'string') {
        const [day, month, year] = date.split('.').map((p) => parseInt(p));
        this.date = new Date(year, month - 1, day, 0, 0, 0, 0);
      } else {
        this.date = date;
      }
    } catch (err) {
    }
    this.performed = performed;
  }
}
