import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { endOfDay, startOfDay } from 'date-fns';
import {
  CalendarEvent,
  CalendarView,
  CalendarDateFormatter,
} from 'angular-calendar';
import { CustomDateFormatter } from '../../../services/calendar/custom-date-formatter.provider';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class CalendarComponent implements OnInit, OnChanges {
  @Input() functionality: string;
  @Input() assignedEvents?: CalendarEvent[];
  @Input() role?: string;
  @Input() exerciseList?: any[];
  @Input() allAppointments?: any[];
  @Input() patientAppointments?: any[];
  @Input() selectedAppointmentSlot?: any;
  @Output() viewChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() exerciseClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() dayClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() storeEvents: EventEmitter<any> = new EventEmitter<any>();
  @Output() dateSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDayClicked?: EventEmitter<any> = new EventEmitter<any>();
  @Output() createAppointmentClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() viewAppointmentClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateAppointmentClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelAppointmentClick: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  events: CalendarEvent[] = [];
  public selectedDates: string[] = [];

  public activeDayIsOpen: boolean = false;
  view: CalendarView;
  public autoSelectionDuration: number = null;
  public autoSelectionOptions = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  public autoSelectionList: number[] = [];
  public newAddedEvents: any[] = [];
  public autoSelectionSelected: boolean = false;

  public todayAppointmentTimes = [];
  public appointmentTimes = [];
  public selectedAppointmentDate = null;
  public selectedAppointmentTime = '';

  locale: string;

  constructor(
    private modalService: NgbModal,
    private translate: TranslateService
  ) {
    this.locale = this.translate.currentLang;
    this.translate.onLangChange.subscribe((value) => {
      this.locale = value.lang;
    });
  }

  ngOnInit(): void {
    this.view = CalendarView.Month;
    this.events = this.assignedEvents;
    this.allAppointments = this.allAppointments?.map((a) => {
      a.formated_time = DateTime.fromISO(a.appointment_dt).toFormat('HH:mm');
      return { ...a };
    });
    this.patientAppointments = this.patientAppointments?.map((a) => {
      a.formated_time = DateTime.fromISO(a.appointment_dt).toFormat('HH:mm');
      return { ...a };
    });
    if (
      this.functionality === 'program-creation' ||
      this.functionality === 'program-update'
    ) {
      this.checkSelectedDates();
    }
    if (this.functionality === 'appointment-selection') {
      this.prepareAppointmentTimes();
      if (this.selectedAppointmentSlot) {
        this.selectedAppointmentDate = this.selectedAppointmentSlot.date;
        this.selectedAppointmentTime = this.selectedAppointmentSlot.time;
        this.patientAppointments = structuredClone(this.patientAppointments);
        const selectedAppIndex = this.patientAppointments.findIndex(
          (app) =>
            app.date.toDateString() ===
            this.selectedAppointmentDate.toDateString()
        );
        this.patientAppointments.splice(selectedAppIndex, 1);
      }
    }
    if (this.functionality === 'dashboard') {
      const appointmentEvents = structuredClone(this.allAppointments);
      appointmentEvents.forEach((app) => {
        app.start = app.date;
        app.title = app.name;
        app.color = {
          primary: '#ffffff',
          secondary: '#384F6B',
        };
        app.allDay = true;
      });
      this.events = appointmentEvents;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.allAppointments = this.allAppointments?.map((a) => {
      a.formated_time = DateTime.fromISO(a.appointment_dt).toFormat('HH:mm');
      return { ...a };
    });
    this.patientAppointments = this.patientAppointments?.map((a) => {
      a.formated_time = DateTime.fromISO(a.appointment_dt).toFormat('HH:mm');
      return { ...a };
    });
    if (changes?.assignedEvents?.currentValue)
      this.events = changes?.assignedEvents?.currentValue;
  }

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  handleDayClick({
    date,
    events,
  }: {
    date: Date;
    events: CalendarEvent[];
  }): void {
    if (this.functionality === 'program-update') {
      this.onDayClicked?.emit?.(date);
      
      return;
    }
    if (this.functionality === 'program-creation') {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      if (date >= today) {
        if (events.length !== 0) {
          let deleteSelectedDate = false;
          const found = this.selectedDates.find(
            (element) => element === date.toDateString()
          );
          if (!found) {
            this.addEvent(date);
          } else {
            events.forEach((event) => {
              if (
                (this.functionality === 'program-creation' &&
                  event.color?.primary === '#1e90ff') ||
                (this.functionality === 'program-update' &&
                  event.color?.primary === '#1e90ff')
              ) {
                this.deleteEvent(event);
                deleteSelectedDate = true;
              }
            });
            if (deleteSelectedDate) {
              const position = this.selectedDates.indexOf(date.toDateString());
              this.selectedDates.splice(position, 1);
            }
          }
        } else {
          this.addEvent(date);
        }
      }
    }
    if (this.functionality === 'patient-details') {
      this.dayClicked.emit({ date, events });
    }
  }

  addEvent(date: Date): void {
    let addSelectedDate = false;
    
    this.exerciseList.forEach((exercise) => {
      if (!exercise.deleted) {
        let tempEventTitle: string;

        if (exercise.display_name) {
          if (exercise.display_name[this.locale]) {
            tempEventTitle = exercise.display_name[this.locale];
          } else {
            tempEventTitle = exercise.display_name['en'];
          }
        } else {
          tempEventTitle = exercise.name;
        }
        const event = {
          title: tempEventTitle,
          start: startOfDay(date),
          end: endOfDay(date),
          color: colors.blue,
          draggable: true,
          resizable: {
            beforeStart: true,
            afterEnd: true,
          },
          id: exercise.exercise_id ?? exercise._id,
          meta: {
            program_id: exercise.program_id,
            exercise_id: exercise.exercise_id ?? exercise._id,
            isProgramDeleted: false,
            isExerciseDeleted: exercise.deleted,
          },
        };
        const exerciseExists = this.events.find(
          (element) =>
            element.id === event.id &&
            element.start.toDateString() === event.start.toDateString()
        );
        if (!exerciseExists) {
          this.events = [...this.events, event];
          this.newAddedEvents.push(event);
          addSelectedDate = true;
        }
      }
    });
    if (addSelectedDate) {
      this.selectedDates.push(date.toDateString());
    }
    // this.storeEvents.emit(this.events, this.newAddedEvents);
    this.storeEvents.emit({
      events: this.events,
      newAddedEvents: this.newAddedEvents,
    });
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
    this.newAddedEvents = this.newAddedEvents.filter(
      (event) => event !== eventToDelete
    );
    this.storeEvents.emit({
      events: this.events,
      newAddedEvents: this.newAddedEvents,
    });
  }

  getAutoSelectionDates(i, d, month, year, days, weekCounter) {
    if (weekCounter < this.autoSelectionDuration) {
      const firstDay = new Date(month + 1 + '/01/' + year);
      const firstDayIndex = firstDay.getDay();

      d.setDate(i);

      if (d.getMonth() < month) {
        d = new Date(year, month, 1);
      }

      // Get the first day of the chosen date in the month
      if (i < firstDayIndex) {
        while (d.getDay() !== i) {
          d.setDate(d.getDate() + 1);
        }
      } else {
        const firstChosenDate = new Date(
          year,
          month,
          firstDay.getDate() + (i - firstDayIndex)
        );
        d = firstChosenDate;
      }

      // Get all the other days in the month
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      while (d.getMonth() === month) {
        if (d >= today && weekCounter < this.autoSelectionDuration) {
          days.push(new Date(d.getTime()));
          weekCounter++;
        }
        d.setDate(d.getDate() + 7);
      }
      d = new Date(year, month + 1);
      month = d.getMonth();
      year = d.getFullYear();
      this.getAutoSelectionDates(i, d, month, year, days, weekCounter);
    }
    return days;
  }

  autoSelection() {
    if (this.autoSelectionSelected) {
      this.clearNewDates();
      this.autoSelectionSelected = false;
    }
    this.viewDate.setHours(0, 0, 0, 0);
    let month = new Date().getMonth();
    let year = new Date().getFullYear();

    const daysInMonth = this.getTotalDaysInMonth(month + 1, year);

    const temporaryDates: Date[] = [];

    this.autoSelectionList.forEach((day) => {
      let date = new Date();
      this.getAutoSelectionDates(day, date, month, year, temporaryDates, 0);
    });

    temporaryDates.forEach((date) => {
      const found = this.selectedDates.find(
        (element) => element === date.toDateString()
      );
      if (!found) {
        this.addEvent(date);
        // this.selectedDates.push(date.toDateString());
      }
    });
    this.autoSelectionSelected = true;
  }

  // input should be month + 1 (real number of month)
  getTotalDaysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  setView(view: CalendarView) {
    this.view = view;
    this.changeViewClick();
  }

  changeViewClick() {
    const dateRange = {
      view: this.view,
      date: this.viewDate,
    };
    this.viewChanged.emit(dateRange);
  }

  getExerciseDetails(action: string, exercise: CalendarEvent) {
    this.exerciseClicked.emit(exercise);
  }

  autoSelectDay(i: number) {
    const index = this.autoSelectionList.indexOf(i);
    if (index > -1) {
      this.autoSelectionList.splice(index, 1);
    } else {
      this.autoSelectionList.push(i);
    }
  }

  isSelected(i: number) {
    return this.autoSelectionList.includes(i);
  }

  openModal(content) {
    this.modalService.open(content, { size: 'lg' });
  }

  clearNewDates() {
    this.events.forEach((event) => {
      if (event.color?.primary === '#1e90ff') {
        if (this.functionality === 'program-update') {
          if (this.newAddedEvents.find((e) => e === event)) {
            this.deleteEvent(event);
          }
        } else {
          this.deleteEvent(event);
        }
      }
    });
    this.selectedDates = [];
    return this.events;
  }

  checkSelectedDates() {
    this.events.forEach((event) => {
      if (event.color?.primary === '#1e90ff') {
        if (!this.selectedDates.includes(event.start.toDateString())) {
          this.selectedDates.push(event.start.toDateString());
        }
      }
    });
  }

  getSelectedDates() {
    return this.selectedDates;
  }

  prepareAppointmentTimes() {
    const currentHr = new Date().getHours();
    for (let i = 0; i < 24; i++) {
      const timeString = i < 10 ? '0' + i + ':' : i + ':';
      if (i > currentHr) {
        this.todayAppointmentTimes.push(timeString + '00');
        this.todayAppointmentTimes.push(timeString + '15');
        this.todayAppointmentTimes.push(timeString + '30');
        this.todayAppointmentTimes.push(timeString + '45');
      }
      this.appointmentTimes.push(timeString + '00');
      this.appointmentTimes.push(timeString + '15');
      this.appointmentTimes.push(timeString + '30');
      this.appointmentTimes.push(timeString + '45');
    }
  }

  selectAppointmentDate(day, timeIndex) {
    if (this.appointmentExists(day, timeIndex)) return;
    this.selectedAppointmentDate = day.date;
    this.selectedAppointmentTime = day.isToday
      ? this.todayAppointmentTimes[timeIndex]
      : this.appointmentTimes[timeIndex];
    const formattedDate = new Date(
      day.date.getTime() - day.date.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split('T')[0];
    this.dateSelect.emit({
      date: formattedDate,
      time: this.selectedAppointmentTime,
    });
  }

  createAppointment() {
    this.createAppointmentClick.emit();
  }

  viewAppointment(appointment) {
    this.viewAppointmentClick.emit(appointment);
  }

  updateAppointment(appointment) {
    this.updateAppointmentClick.emit(appointment);
  }

  cancelAppointment(appointment) {
    this.cancelAppointmentClick.emit(appointment);
  }

  patientAppOnDay(day) {
    return this.patientAppointments?.some(
      (app) => app.date.toDateString() === day.date.toDateString()
    );
  }

  appointmentExists(day, timeIndex) {
    return this.allAppointments.some((app) => {
      if (app.date.toDateString() !== day.date.toDateString()) return false;
      const appTimes = day.isToday
        ? this.todayAppointmentTimes
        : this.appointmentTimes;
      for (let i = timeIndex; i >= 0 && timeIndex - i < 4; i--) {
        if (app.time === appTimes[i]) return true;
      }
      return false;
    });
  }
}
