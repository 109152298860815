import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
} from '@angular/core';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ExerciseService } from '../../../services/exercise/exercise.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { DataService } from '@services/data.service';
import { addAlldates, buildCalendarEvents } from '@helpers/helper';
import { GeneralService } from '@services/general/general.service';
import { forkJoin } from 'rxjs';
import { StoreService } from '@services/store.service';

@Component({
  selector: 'app-create-exercise-program',
  templateUrl: './create-exercise-program.component.html',
  styleUrls: ['./create-exercise-program.component.css'],
})
export class CreateExerciseProgramComponent implements OnInit {
  @Input() user: any;
  @Input() patientUid: string;
  @Input() assignedExercises: any[] = [];
  @Output() closeProgramEvent = new EventEmitter<string>();

  public allExercises: any[];
  public dataLoaded: boolean = false;
  public copiedDataLoaded: boolean = false;
  public templatesLoaded: boolean = false;

  public exerciseSelection: boolean = true;
  public programCreation: boolean = false;
  public chosenExercises: any[] = [];
  public exerciseFilterOptions = [
    'none',
    'breathing',
    'lower_body',
    'stretch',
    'sit',
    'stand',
    'supine',
  ];
  public exerciseFilterSelected: string = 'none';
  public searchExerciseText: string = '';
  public programCreated: boolean = false;

  // templates
  public templatesOpen: boolean = true;
  public templateSelected: boolean = false;
  public programTemplates: any[] = [];
  public extendedTemplates: any[] = [];
  public searchExerciseTemplate: string = '';
  public templateDeletionIndex: number = -1;

  // pagination
  public exercisePage: number = 1;
  public exercisePageSize: number = 5;
  public chosenExercisesPage: number = 1;
  public chosenExercisesPageSize: number = 5;

  model: NgbDateStruct;
  date: { year: number; month: number };
  public selectedDates: NgbDateStruct[] = [];
  public calendarComponent: any;
  public language: string;
  dataArr: string[] = [];
  public newAddedEvents: any[] = [];
  public gifUrls: any[] = [];
  selected_template_available_exercises: any[];
  constructor(
    private exerciseService: ExerciseService,
    private modalService: NgbModal,
    private calendar: NgbCalendar,
    private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private dataService: DataService,
    private storeService: StoreService,
    private generalService: GeneralService,
    private ngZone: NgZone
  ) {
    this.language = this.translate.currentLang;
    this.user = this.auth.getAuthenticatedUser();

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.language = event.lang;
    });
    this.route.data.subscribe(({ data }) => {
      this.processAssignedExercises(data.allExercisePrograms);
      this.allExercises = data.allExercises;
      this.allExercises.forEach((ex) => {
        ex.ai_enabled = true;
      })
      this.programTemplates = data.programTemplates;
      this.extendedTemplates = structuredClone(data.programTemplates);
    });
  }
  processAssignedExercises(assignedExercises: any[]) {
    for (const program of assignedExercises) {
      if (program?.exercises && !program?.deleted) {
        for (const exercise of program.exercises) {
          buildCalendarEvents(
            exercise,
            this.language,
            new Date(),
            [],
            this.assignedExercises,
            [],
            []
          );
          //addAlldates(exercise.assigned_dates, this.allExercisesDates, this.todayExercises, this.translate);
        }
      }
    }
  }
  ngOnInit(): void {
    this.generalService.getAllGifs().subscribe((urls) => {
      this.gifUrls = urls;
      this.gifUrls.forEach((urlPromise) => {
        urlPromise.then((url) => {
          this.dataArr.push(url);
        });
      });
      this.ngZone.run(() => {
        this.dataLoaded = true;
        this.templatesLoaded = true;
      });
    });
    this.route.paramMap.subscribe((p) => {
      this.patientUid = p.get('id');
    });
  }

  getGifLinks(exerciseName: string): string | undefined {
    if (exerciseName === 'sit2stand_test') {
      exerciseName = 'squat.gif';
    }
    if (exerciseName === 'diaphragm') {
      exerciseName = 'breath.gif';
    }
    if (exerciseName === 'lip') {
      exerciseName = 'breath.gif';
    }
    return this.dataArr.find((link) => link.includes(exerciseName));
  }

  // CRUD methods

  createProgram(calendarComponent) {
    this.programCreated = true;
    const selectedDates = calendarComponent.selectedDates;
    const assignedExercises: any[] = [];
    this.chosenExercises.forEach((exercise) => {
      const assignedDates: string[] = [];
      this.newAddedEvents.forEach((event) => {
        if (event.title === exercise.display_name[this.language]) {
          assignedDates.push(this.changeDateString(event.start));
        }
      });
      let obj = {
        exercise_id: exercise._id,
        assigned_dates: assignedDates,
        sets: exercise.sets,
        set_break: exercise.set_break,
        repetitions: exercise.repetitions,
        actions: exercise.actions,
        initial_pose:exercise.initial_pose
      };
      assignedExercises.push(obj);
    });
    const requestData = {
      patient_uid: this.patientUid,
      assigned_exercises: assignedExercises,
    };

    this.exerciseService.createExerciseProgram(requestData).subscribe(
      (data) => {
        this.translate
          .get('toastr.exerciseProgramCreationSuccess')
          .subscribe((res: string) => {
            this.toastr.success(res);
          });
      },
      (error) => {
        this.translate
          .get('toastr.exerciseProgramCreationFail')
          .subscribe((res: string) => {
            this.toastr.error(res);
          });
      },
      () => {
        this.closeProgramCreation(true);
      }
    );
  }

  // helper methods

  chooseExercise(exercise: any) {
    //console.log({exercise})
    let valid = this.checkExerciseInputValidation(exercise);
    if (valid || this.isExerciseChosen(exercise)) {
      const objectPosition = this.chosenExercises.indexOf(exercise);
      if (objectPosition === -1) {
        this.chosenExercises.push(exercise);
      } else {
        this.chosenExercises.splice(objectPosition, 1);
      }
    } else {
      const errorField = this.getErrorField(exercise);
      this.translate
        .get('toastr.exerciseProgramValidationError')
        .subscribe((res: string) => {
          if (exercise.display_name && exercise.display_name[this.language]) {
            this.translate
              .get('toastr.exerciseFieldValidationError')
              .subscribe((fieldErr: string) => {
                this.translate
                  .get(`createExerciseProgram.${errorField}`)
                  .subscribe((fieldName: string) => {
                    this.toastr.error(
                      exercise.display_name[this.language] +
                      ' ' +
                      res.substring(res.indexOf(' ') + 1) +
                      '. \n' +
                      fieldErr +
                      ' ' +
                      fieldName
                    );
                  })

              })
          } else {
            this.toastr.error(res);
          }
        });
    }
  }

  getErrorField(exercise: any): string {
    if (
      (exercise.sets || exercise.sets === 0) &&
      (exercise.sets <= 0 || exercise.sets > 10)
    ) {
      return 'sets';
    }
    if (exercise.set_break?.duration && exercise.set_break.duration < 25) {
      return 'break';
    }
    if (exercise.category == 'breathing') {
      if (exercise.repetitions <= 0 || exercise.repetitions > 9) {
        return 'repetitions';
      }
      let field = '';
      exercise.actions.forEach((action) => {
        if (
          !action.duration ||
          parseInt(action.duration) <= 0 ||
          parseInt(action.duration) > 10
        ) {
          field = action.name;
        }
      });
      if (field) {
        return field;
      }
    }
    if (exercise.category == 'stretch') {
      if (exercise.repetitions <= 0 || exercise.repetitions > 9) {
        return 'repetitions';
      }
      let field = '';
      exercise.actions.forEach((action) => {
        if ('count' in action) {
          if (
            !action.count ||
            parseInt(action.count) <= 0 ||
            parseInt(action.count) > 30
          ) {
            field = 'count';
          }
        }
        if ('duration' in action) {
          if (
            !action.duration ||
            parseInt(action.duration) <= 0 ||
            parseInt(action.count) > 600
          ) {
            field = 'duration';
          }
        }
      });
      if (field) {
        return field;
      }
    }
    if (exercise.category == 'lower_body') {
      if (exercise.repetitions <= 0 || exercise.repetitions > 9) {
        return 'repetitions';
      }
      let field = '';
      exercise.actions.forEach((action) => {
        if ('count' in action) {
          if (
            !action.count ||
            parseInt(action.count) <= 0 ||
            parseInt(action.count) > 30
          ) {
            field = 'count';
          }
        }
        if ('duration' in action) {
          if (
            !action.duration ||
            parseInt(action.duration) <= 0 ||
            parseInt(action.duration) > 600
          ) {
            field = 'duration';
          }
        }
      });
      if (field) {
        return field;
      }
    }
    if (exercise.category == 'breathing_time') {
      return 'duration';
    }
    if (exercise.category == 'relaxation') {
      return 'duration';
    }
    return '';
  }

  checkExerciseInputValidation(exercise) {
    let valid = true;
    if (
      (exercise.sets || exercise.sets === 0) &&
      (exercise.sets <= 0 || exercise.sets > 10)
    ) {
      return false;
    }
    if (exercise.set_break?.duration && exercise.set_break.duration < 25) {
      return false;
    }
    if (exercise.category == 'breathing') {
      valid = this.checkBreathingInputValidation(exercise);
    }
    if (exercise.category == 'stretch') {
      valid = this.checkStretchInputValidation(exercise);
    }
    if (exercise.category == 'lower_body') {
      valid = this.checkLowerBodyInputValidation(exercise);
    }
    if (exercise.category == 'breathing_time') {
      valid = exercise.actions[1].duration >= 15;
    }
    if (exercise.category == 'relaxation') {
      valid = exercise.actions[0].duration >= 15;
    }
    return valid;
  }

  checkStretchInputValidation(exercise) {
    if (exercise.repetitions <= 0 || exercise.repetitions > 9) {
      return false;
    }
    let valid = true;
    exercise.actions.forEach((action) => {
      if ('count' in action) {
        if (
          !action.count ||
          parseInt(action.count) <= 0 ||
          parseInt(action.count) > 30
        ) {
          valid = false;
        }
      }
      if ('duration' in action) {
        if (
          !action.duration ||
          parseInt(action.duration) <= 0 ||
          parseInt(action.count) > 600
        ) {
          valid = false;
        }
      }
    });
    return valid;
  }

  checkLowerBodyInputValidation(exercise) {
    if (exercise.repetitions <= 0 || exercise.repetitions > 9) {
      return false;
    }
    let valid = true;
    exercise.actions.forEach((action) => {
      if ('count' in action) {
        if (
          !action.count ||
          parseInt(action.count) <= 0 ||
          parseInt(action.count) > 30
        ) {
          valid = false;
        }
      }
      if ('duration' in action) {
        if (
          !action.duration ||
          parseInt(action.duration) <= 0 ||
          parseInt(action.duration) > 600
        ) {
          valid = false;
        }
      }
    });
    return valid;
  }
  onInnerDivClick(event: Event): void {
    event.stopPropagation();
  }

  checkBreathingInputValidation(exercise) {
    if (exercise.repetitions <= 0 || exercise.repetitions > 9) {
      return false;
    }
    var valid = true;
    exercise.actions.forEach((action) => {
      if (
        !action.duration ||
        parseInt(action.duration) <= 0 ||
        parseInt(action.duration) > 10
      ) {
        valid = false;
      }
    });

    return valid;
  }

  isExerciseChosen(exercise: any) {
    if (this.chosenExercises.indexOf(exercise) === -1) {
      return false;
    }
    return true;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.chosenExercises,
      event.previousIndex,
      event.currentIndex
    );
  }
  onBackButton(dialog) {
    if (this.chosenExercises.length > 0) this.openModal(dialog);
    else this.closeProgramCreation();
  }
  openModal(content, component?) {
    this.modalService.open(content, { size: 'lg' });
    if (component) {
      this.calendarComponent = component;
    }
  }

  closeProgramCreation(reload = false) {
    //this.storeService.getPrograms(this.patientUid,true).subscribe(_=>{

    this.router.navigate([this.patientUid], {
      relativeTo: this.route.parent,
      state: { reload },
    });
    //})
  }

  select(e) {
    this.selectedDates.push(e);
  }

  isSelected(date) {
    const objectPosition = this.selectedDates.indexOf(date);
    if (objectPosition === -1) {
      return false;
    }
    return true;
  }

  selectedDatesEmpty(calendarComponent) {
    const selectedDates = calendarComponent.selectedDates;
    if (selectedDates.length === 0) {
      return true;
    }
    return false;
  }

  changeDateString(date: string) {
    const newDate = new Date(Date.parse(date));

    const day = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();

    return day + '.' + month + '.' + year;
  }

  clearDates() {
    var events = this.calendarComponent.clearNewDates();
    this.assignedExercises = events;
  }

  backToExerciseSelection(calendarComponent) {
    if (this.templateSelected) {
      this.templateSelected = false;
      this.chosenExercises = [];
    }
    this.programCreation = false;
    this.exerciseSelection = true;
    calendarComponent.selectedDates = [];
  }

  storeTemporaryExercises(data) {
    this.assignedExercises = data.events;
    this.newAddedEvents = data.newAddedEvents;
  }

  onChangingStretchDuration(exercise) {
    if (exercise.actions[3] && 'duration' in exercise.actions[3]) {
      exercise.actions[3].duration = exercise.actions[1].duration;
    }
  }

  next() {
    var valid = true;
    this.chosenExercises.forEach((chosenExercise) => {
      var checkValidation = this.checkExerciseInputValidation(chosenExercise);
      if (!checkValidation) {
        valid = false;
        const errorField = this.getErrorField(chosenExercise);
        this.translate
          .get('toastr.exerciseProgramValidationError')
          .subscribe((res: string) => {
            if (
              chosenExercise.display_name &&
              chosenExercise.display_name[this.language]
            ) {
              this.translate
              .get('toastr.exerciseFieldValidationError')
              .subscribe((fieldErr: string) => {
                this.translate
                  .get(`createExerciseProgram.${errorField}`)
                  .subscribe((fieldName: string) => {
                    this.toastr.error(
                      chosenExercise.display_name[this.language] +
                      ' ' +
                      res.substring(res.indexOf(' ') + 1) +
                      '. \n' +
                      fieldErr +
                      ' ' +
                      fieldName
                    );
                  })

              })
            } else {
              this.toastr.error(res);
            }
          });
      }
    });
    if (valid) {
      this.exerciseSelection = false;
      this.programCreation = true;
    }
  }

  getExerciseGif(event, exercise) {
    event.target.src = '../../../assets/images/Breathment Logo_rev_white.png';
  }

  checkKeyExists(key: string, obj: any) {
    return key in obj;
  }

  toggleTemplates() {
    this.templatesOpen = !this.templatesOpen;
    
  }
  toggleTemplate(template){
    template.expanded = !template.expanded; 
    this.refreshSelectedTemplateExercisesList(template)
  }
  refreshSelectedTemplateExercisesList(template) {
    this.selected_template_available_exercises = this.allExercises.filter(
      (e) => {
        if (template.exercise_program.find((ex) => ex._id == e._id)) return false;
        return true;
      }
    );
  }
  selectTemplate(template) {
    let valid = true;
    template.exercise_program.forEach((chosenExercise) => {
      const checkValidation = this.checkExerciseInputValidation(chosenExercise);
      if (!checkValidation) {
        valid = false;
        const errorField = this.getErrorField(chosenExercise);
        this.translate
          .get('toastr.exerciseProgramValidationError')
          .subscribe((res: string) => {
            if (
              chosenExercise.display_name &&
              chosenExercise.display_name[this.language]
            ) {
              this.translate
              .get('toastr.exerciseFieldValidationError')
              .subscribe((fieldErr: string) => {
                this.translate
                  .get(`createExerciseProgram.${errorField}`)
                  .subscribe((fieldName: string) => {
                    this.toastr.error(
                      chosenExercise.display_name[this.language] +
                      ' ' +
                      res.substring(res.indexOf(' ') + 1) +
                      '. \n' +
                      fieldErr +
                      ' ' +
                      fieldName
                    );
                  })

              })
            } else {
              this.toastr.error(res);
            }
          });
      }
    });
    if (valid) {
      this.chosenExercises = structuredClone(template.exercise_program);
      this.templateSelected = true;
      this.exerciseSelection = false;
      this.programCreation = true;
      this.extendedTemplates.forEach((t) => {
        t.expanded = false;
      });
      template.expanded = true;
      this.refreshSelectedTemplateExercisesList(template)
    }
  }

  addTemplate() {
    const template = {
      _id: null,
      template_name: '',
      exercise_program: [],
      expanded: true,
      edited: true,
    }
    this.extendedTemplates.push(template);
    this.refreshSelectedTemplateExercisesList(template)
  }

  revertTemplate(index) {
    this.extendedTemplates[index] = structuredClone(
      this.programTemplates[index]
    );
    this.extendedTemplates[index].expanded = true;
    this.extendedTemplates[index].edited = false;
    this.refreshSelectedTemplateExercisesList(this.extendedTemplates[index])
  }

  saveTemplate(template, index) {
    let valid = true;
    template.exercise_program.forEach((chosenExercise) => {
      const checkValidation = this.checkExerciseInputValidation(chosenExercise);
      if (!checkValidation) {
        valid = false;
        const errorField = this.getErrorField(chosenExercise);
        this.translate
          .get('toastr.exerciseProgramValidationError')
          .subscribe((res: string) => {
            if (
              chosenExercise.display_name &&
              chosenExercise.display_name[this.language]
            ) {
              this.translate
              .get('toastr.exerciseFieldValidationError')
              .subscribe((fieldErr: string) => {
                this.translate
                  .get(`createExerciseProgram.${errorField}`)
                  .subscribe((fieldName: string) => {
                    this.toastr.error(
                      chosenExercise.display_name[this.language] +
                      ' ' +
                      res.substring(res.indexOf(' ') + 1) +
                      '. \n' +
                      fieldErr +
                      ' ' +
                      fieldName
                    );
                  })

              })
            } else {
              this.toastr.error(res);
            }
          });
      }
    });

    if (!valid) return;

    if (template._id) {
      this.exerciseService
        .updateProgramTemplate({
          template_name: template.template_name,
          template_id: template._id,
          exercise_program: template.exercise_program,
        })
        .subscribe({
          error: (e) => {
            this.translate
              .get('toastr.templates.updateError')
              .subscribe((res: string) => {
                this.toastr.error(res);
              });
          },
          complete: () => {
            template.edited = false;
            this.programTemplates[index] = structuredClone(template);
            this.translate
              .get('toastr.templates.updateSuccess')
              .subscribe((res: string) => {
                this.toastr.success(res);
              });
          },
        });
    } else {
      this.exerciseService
        .createProgramTemplate({
          template_name: template.template_name,
          exercise_program: template.exercise_program,
        })
        .subscribe({
          next: (data) => {
            template._id = data['data'] ?? '';
          },
          error: (e) => {
            this.translate
              .get('toastr.templates.createError')
              .subscribe((res: string) => {
                this.toastr.error(res);
              });
          },
          complete: () => {
            template.edited = false;
            this.programTemplates.push(structuredClone(template));
            this.translate
              .get('toastr.templates.createSuccess')
              .subscribe((res: string) => {
                this.toastr.success(res);
              });
          },
        });
    }
  }

  deleteTemplate(index) {
    if (this.extendedTemplates[index]._id) {
      this.exerciseService
        .deleteProgramTemplate({
          template_id: this.extendedTemplates[index]._id,
        })
        .subscribe({
          error: (e) => {
            this.translate
              .get('toastr.templates.deleteError')
              .subscribe((res: string) => {
                this.toastr.error(res);
              });
          },
          complete: () => {
            this.programTemplates.splice(index, 1);
            this.extendedTemplates.splice(index, 1);
            this.translate
              .get('toastr.templates.deleteSuccess')
              .subscribe((res: string) => {
                this.toastr.success(res);
              });
          },
        });
    } else {
      this.extendedTemplates.splice(index, 1);
    }
  }

  removeTemplateExercise(template, index) {
    template.exercise_program.splice(index, 1);
    template.edited = true;
    this.refreshSelectedTemplateExercisesList(template)
  }

  addTemplateExercise(template, exerciseOption) {
    template.exercise_program.push(structuredClone(exerciseOption));
    template.edited = true;
    this.refreshSelectedTemplateExercisesList(template)
  }

  programHasExercise(exerciseProgram, exercise) {
    return exerciseProgram.some((ex) => ex._id === exercise._id);
  }

  updateExerciseHoldDuration($event, exercise) {
    if (exercise.actions.length > 3) {
      exercise.actions[3].duration = Number($event.target.value);
    }
  }
}
