import { Component, EventEmitter, OnInit, Output, ViewChild, ElementRef, Input } from '@angular/core';

import { NgZone } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { closeTheCamera, convertDataURIToBinary, delayX, getWarningKey, secondsCounter } from '@helpers/helper';
import { VideoService } from '@services/video.service';
import { ExerciseService } from '@services/exercise/exercise.service';
import { GeneralService } from '@services/general/general.service';
import { AuthService } from '@services/auth.service';
import { IUserProfile } from 'src/app/resolvers';
import { SoundPlayer, WarningTracker } from '@helpers/sound-player';

@Component({
  selector: 'app-breathing-exercise',
  templateUrl: './breathing-exercise.component.html',
  styleUrls: ['./breathing-exercise.component.css'],
})
export class BreathingExerciseComponent implements OnInit {

  @ViewChild('cameraVideo', { static: true }) video: ElementRef<HTMLVideoElement>;
  @ViewChild('mainInstructionVideo', { static: true }) instructionVideo: ElementRef<HTMLVideoElement>;
  @ViewChild('c1', { static: true }) private c1: any;
  @Output() closeTestViewEvent = new EventEmitter<string>();
  @Input() exercise: any;
  @Input() exerciseDate: string;
  @Input() firstExercise: boolean;

  user: IUserProfile;
  public dataLoaded = false;
  public videoDimensions: number;
  public videoMarginBottom: number;
  public timerPositionLeft: number;
  public timerPositionTop: number;
  public playAudioInstruction: boolean = true;
  public audioInstructionArray: any[] = [];
  public playWarningAudio: boolean = true;
  public raiseArmAudio: boolean = true;

  public exerciseData: any[] = [];
  public exerciseResults: any[] = [];
  public exerciseWarnings: any[] = [];
  public breathValue: number = 50;
  public breathChestValue: number = 50;
  public shoulderEvaluation: boolean = true;
  public postureEvaluation: number;
  public previousKeyPoints: any[] = [];
  public confidence: any;

  public bodyPixInterval = null;
  public isProcessing: boolean = false;

  public exerciseStarted: boolean = false;
  public countdown: boolean = false;
  public countdownNumber: number;
  public countdownTotal: number;
  public countdownPercentage: number;

  public instructionCounter: number = 0;
  public exerciseFinished: boolean = false;
  public resultsUploaded: boolean = false;

  public displayAITrainerInstructionVideo: boolean = null;
  public videoInstructionUrl: any;
  public nextClickCounter: number = 0;
  public isSingleNextClick: boolean = true;
  public countdownTimer: any;
  public calculationsTimer: any;
  public instructionVideoType: string;

  public poseWarning: boolean = false;
  public showWarningImage: boolean = true;

  public viewDate: Date = new Date();

  // animation
  grow = false;
  previousBreath: number = 0;
  public correctBreathing: boolean;

  public initialPose: boolean = false;

  public beepSound: any;
  public instructionSound: any;
  public audioSource: any;
  public gifUrls: any[] = [];
  public dataArr: string[] = [];
  public beforeExerciseDisplay: boolean = true;
  public serverError: boolean = false;
  public exerciseName: string = '';
  public gifUrl: string = '';
  public exerciseFeedback: string[] = ['Try to focus more on inhaling.', 'Try to focus more on exhaling.', 'Try to focus more on sitting straight.', 'Try not to increase your shoulders while inhaling.'];
  public positiveFeedback: string;
  public negativeFeedback: string[] = [];
  public showHints: boolean = false;

  public sets: number = 1;
  public setBreakDuration: number = 0;
  public setBreakAudioUrl: string = '';

  public playingInstructionAudio = false;
  public playingWarningAudio = false;
  public audioWarnings = null;

  public language: string;
  activeActionDetails: { started_at: number; ms_duration: number; } = {
    started_at: 0,
    ms_duration: 0
  };
  current_instruction_seconds_range: number = 0;
  total_seconds_passed: number = 0;
  constructor(
    private videoService: VideoService,
    private exerciseService: ExerciseService,
    private ngZone: NgZone,
    private translate: TranslateService,
    private generalService: GeneralService,
    private authService: AuthService
  ) {
    // this.language = this.authService.getAuthenticatedUser().locale;
    this.language = this.translate.currentLang;
    this.getAudioWarningTranslations();
    this.translate.onLangChange.subscribe(value => {
      this.translate.use(value.lang);
      this.language = value.lang;
      this.getAudioWarningTranslations();
    });
    this.user = this.authService.me;

  }
  beepPlayer: SoundPlayer;
  mp3Player: SoundPlayer;

  ngOnInit(): void {
    this.beepPlayer = new SoundPlayer('beepAudioPlayer')
    this.mp3Player = new SoundPlayer('mp3AudioPlayer')

    this.sets = this.exercise.sets;
    if (this.exercise.set_break?.active) {
      this.setBreakDuration = this.exercise.set_break.duration;
    }
    this.getAudioInstructionsFromServer(this.exercise);
    setTimeout(() => {
      this.dataLoaded = true;
    }, 700);
    this.getGifLinks();
  }

  getGifLinks(): string | undefined {
    this.exerciseName = this.exercise.name + '.gif';
    if (this.exerciseName === 'sit2stand_test.gif') {
      this.exerciseName = 'squat.gif'
    }
    if (this.exerciseName === 'diaphragm.gif' || this.exerciseName === 'lip.gif') {
      this.exerciseName = 'breath.gif'
    }
    this.generalService.getGifByName(this.exerciseName).subscribe(url => {
      this.gifUrl = url;

    });
    return this.gifUrl;
  }
  // Animation
  get stateName() {
    // return this.instructionCounter%2 !== 0 ? 'grow' : 'shrink'
    return this.grow ? 'grow' : 'shrink';
  }
  // Animation


  ngAfterContentInit(): void {
    // this.initWebcam();
  }

  ngOnDestroy(): void {
    // clearInterval(this.bodyPixInterval);
    // this.video.nativeElement.removeEventListener('play', this.onPlay);
  }

  async getAudioInstructionsFromServer(exercise: any) {
    for (let i = 0; i < exercise.actions.length; i++) {
      const instruction = exercise.actions[i].instruction[this.language];
      const requestData = { warning: instruction, language: this.language };
      const resp = await this.generalService.getTextAudio(requestData).toPromise();
      const datas = resp['data'];
      let binary = convertDataURIToBinary(datas);
      let blob = new Blob([binary], { type: 'audio/ogg' });
      const blobUrl = URL.createObjectURL(blob);
      this.audioInstructionArray.push(blobUrl);
    }
    if (this.exercise.set_break?.active && this.exercise.set_break.duration > 0) {
      const instruction = exercise.set_break.instruction[this.language];
      const requestData = { warning: instruction, language: this.language };
      const resp = await this.generalService.getTextAudio(requestData).toPromise();
      const binary = convertDataURIToBinary(resp['data']);
      this.setBreakAudioUrl = URL.createObjectURL(new Blob([binary], { type: 'audio/ogg' }));
    }
  }

  getAudioWarningTranslations() {
    this.generalService.getAudioTranslations({ lang: this.language }).subscribe((res) => {
      this.audioWarnings = res?.data?.audioWarnings;

    },
      (err) => {
        console.error('error retrieving audio translations: ', err);
      })
  }


  initWebcam() {
    const video = this.video.nativeElement;

    if (navigator.mediaDevices) {
      navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
        this.video.nativeElement.srcObject = stream;
        this.video.nativeElement.addEventListener('play', this.onPlay);
      }).catch(e => console.error(e));
    }
  }

  onPlay = () => {
    this.stopped = false;
    const { handleImage } = this.getContext()

    if (this.exerciseStarted) {
      handleImage(this.getImages)

    } else if (!this.exerciseFinished && !this.displayAITrainerInstructionVideo) {
      this.playWarning({ message: 'silhouettePosition' });
      handleImage(this.checkInitialPose)
    } else {
      clearInterval(this.bodyPixInterval);

      this.bodyPixInterval = setInterval(() => {

        if (!this.isProcessing) {
          const { context, imageFrame } = this.getContext()
          context.drawImage(imageFrame, 0, 0, imageFrame.width, imageFrame.height, 0, 0, imageFrame.width, imageFrame.width * (imageFrame.height / imageFrame.width));
        }
      }, 100);
    }
  }
  now: number;
  breakSecond: number;
  //time_shift: number = 0;
  isInInstructionBreak() {

    let current_second = this.first_frame_time == 0 ? 0 : (this.now - this.first_frame_time);

    if (this.first_frame_time === 0) return false

    const skip = Math.floor(current_second / 1000) === this.total_seconds_passed + this.current_instruction_seconds_range ||
      (current_second / 1000 >= (this.total_seconds_passed + this.current_instruction_seconds_range - 1));
    return skip;
  }
  first_frame_time: number = 0

  getImages = (file) => {

    if (file) {
      this.ngZone.run(() => {
        this.isProcessing = true;
        const { handleImage } = this.getContext()

        this.now = Date.now();

        if (this.playingInstructionAudio) {
          delayX(50, () => handleImage(this.getImages));
          return;
        }
        if (this.first_frame_time === 0) {
          this.first_frame_time = this.now;
        }


        const formData = new FormData();
        formData.append('image', file);
        formData.append('category', this.exercise.category);
        formData.append('name', this.exercise.name);
        formData.append('prev_key_points', JSON.stringify(this.previousKeyPoints));
        formData.append('uid', this.user.id);
        if (this.confidence) {
          formData.append('confidence_mean', this.confidence);
        }

        const requestData = {
          image: file,
          category: this.exercise.category,
          name: this.exercise.name,
          prev_key_points: JSON.stringify(this.previousKeyPoints),
          uid: this.user.id,
          base64: true,
          vertical: false,
          iOS: true
        }
        if (this.confidence) {
          requestData['confidence_mean'] = this.confidence;
        }

        this.videoService.sendFrame(requestData).subscribe((data) => {
          if (data.warning) {
            this.playWarning(data.warning)
          }
          if (data["data"]) {

            this.poseWarning = false;

            if (data['data'].prev_key_points.length > 0) {
              this.previousKeyPoints = data['data'].prev_key_points;
            }
            if (data['data'].confidence_mean) {
              this.confidence = data['data'].confidence_mean;
            }
            let rawData = data["data"];
            rawData["timestamp"] = this.now;
            if (this.instructionCounter % 2 === 0) {
              rawData["instruction"] = "inhale";
            } else if (this.instructionCounter % 2 !== 0) {
              rawData["instruction"] = "exhale";
            }
            delete rawData.prev_key_points;
            delete rawData.confidence_mean;
            this.exerciseData.push(rawData);
            if (this.exerciseData.length > 3) {
              this.calculateBreathe();
            }
          }
        },
          (err) => {
            if (err.error.error.message) {
              this.poseWarning = true;
            }

            handleImage(this.getImages);

          },
          () => {

            handleImage(this.getImages);
          });
      });
    }
  }
  startGestureTriggered: boolean = false;
  initialPoseCounter = 0
  search_for_start_gesture = 0
  checkInitialPose = (file) => {
    if (file) {
      this.ngZone.run(() => {
        if (!this.videoDimensions) {
          this.getVideoDimensions();
        }

        // Run the code here
        this.isProcessing = true;
        const { handleImage } = this.getContext()
        // const formData = new FormData();
        // formData.append('image', file)
        // formData.append('category', this.exercise.category)
        // formData.append('name', this.exercise.name)
        // formData.append('uid', this.user.id)

        const requestData = {
          image: file,
          category: this.exercise.category,
          name: this.exercise.name,
          uid: this.user.id,
          base64: true,
          vertical: false,
          iOS: true
        }

        this.videoService.checkPose(requestData).subscribe((data) => {
          this.initialPose = data['initial_pose'];
          const warning = data['warning'];
          if (warning && this.playWarningAudio) {
            this.playWarning(warning);
          }
          if (!this.initialPose) {
            this.initialPoseCounter = 0;
            handleImage(this.checkInitialPose);
            return;
          }
          this.initialPoseCounter++;
          if (this.initialPoseCounter === 2) {
            handleImage(null)
            this.mp3Player.pause();
            this.initialPoseCounter++;
            if (this.raiseArmAudio) {
              this.playWarning({ message: 'raiseArmToStart' });
            }
            handleImage(this.checkStartGesture);
          } else handleImage(this.checkInitialPose);
        },
          (err) => {

            if (err.status === 0) {
              this.serverError = true;
              this.stopExercise();
            }
          },
          () => {

          });
      });

    }
  }

  checkStartGesture = (file) => {
    if (file) {
      this.ngZone.run(() => {
        // Run the code here
        this.isProcessing = true;
        const { handleImage } = this.getContext()

        const formData = new FormData();
        // formData.append('image', file)
        // formData.append('category', this.exercise.category)
        // formData.append('name', this.exercise.name)
        // formData.append('uid', this.user.id)

        const requestData = {
          image: file,
          category: this.exercise.category,
          name: this.exercise.name,
          uid: this.user.id,
          base64: true,
          vertical: false
        }


        this.videoService.checkStartGesture(requestData).subscribe((data) => {

          if (data['exercise_start'] === true) {
            handleImage(null);
            this.mp3Player.pause();

            this.startGestureTriggered = true;

            this.startCountdown();

          } else {
            this.search_for_start_gesture++;
            if (this.search_for_start_gesture > 100) {
              this.search_for_start_gesture = 0
              this.initialPoseCounter = 0;
              handleImage(this.checkInitialPose);
            } else {
              handleImage(this.checkStartGesture);

            }
          }
        },
          (err) => {

          });
      });

    }
  }

  uploadExerciseResults() {
    const requestData = {
      date: this.exerciseDate,
      exercise_id: this.exercise.exercise_id,
      raw_data: this.exerciseData,
      processed_data: this.exerciseResults,
      confidence_mean: this.confidence,
      sets: this.exercise.repetitions,
      inhale_duration: this.exercise.actions[0].duration,
      exhale_duration: this.exercise.actions[1].duration
    }
    this.exerciseService.uploadResults(requestData).subscribe(data => {
      this.resultsUploaded = true;
    },
      error => {

      },
      () => {
        this.getExerciseResults();
      });
    // }
  }

  getExerciseResults() {
    if ((this.exercise.name === 'lip') || (this.exercise.name === 'diaphragm')) {
      const requestData = {
        patient_uid: this.user.id,
        exercise_id: this.exercise.exercise_id,
        date: this.exerciseDate
      }
      this.exerciseService.getSessionResults(requestData).subscribe(returnData => {
        try {
          const sessionData = returnData['data'];
          const comparisonArray = [];
          comparisonArray.push({ action: "Inhalation", value: sessionData.breathing_analysis.percz_analy_inhale.value });
          comparisonArray.push({ action: "Exhalation", value: sessionData.breathing_analysis.percz_analy_exhale.value });
          comparisonArray.push({ action: "Posture", value: sessionData.posture_analysis.percz_analy.value });
          comparisonArray.push({ action: "Shoulder", value: sessionData.shoulder_analysis.value });

          comparisonArray.sort((a, b) => parseFloat(a.value) - parseFloat(b.value));
          let counter = comparisonArray.length - 1;
          let bestElement = comparisonArray[counter];
          while (bestElement.action === 'Shoulder') {
            counter--;
            bestElement = comparisonArray[counter];
          }
          this.positiveFeedback = 'positive' + bestElement.action;

          for (let i = 0; i < 2; i++) {
            if (comparisonArray[i].value < 70) {
              let feedback = 'focus' + comparisonArray[i].action;
              this.negativeFeedback.push(feedback);
            }
          }
        } catch (error) {

        }
      },
        (error) => {

        },
        () => {
          this.showHints = true;
        });
    }

  }

  // Helper functions

  closeTestView() {
    // if (this.displayAITrainerInstructionVideo) {
    //   this.displayAITrainerInstructionVideo = false;
    // } else if (!this.exerciseFinished) {
    //   this.stopExercise();
    // }
    if (this.exerciseStarted) {
      this.generalService.stop();
      this.stopExercise(true);
    }
    this.closeTestViewEvent.emit();
    closeTheCamera.apply(this)
  }

  startCountdown() {
    this.countdown = true;
    this.countdownTotal = 5;
    this.countdownNumber = 5;
    secondsCounter(5, (timeleft) => {

      this.beep('second');
      this.countdownNumber = timeleft;
      this.countdownPercentage = 100 - ((this.countdownNumber / this.countdownTotal) * 100);


    }, (v) => {
      this.beep('break');
      this.countdown = false;
      this.exerciseStarted = true;
      this.onPlay();
      this.startExercise();
    })

  }
  startSendingFrames() {
    const { handleImage } = this.getContext()
    if (this.exerciseStarted) {

      handleImage(this.getImages);
    }
  }
  startExercise() {
    let exerciseDuration = this.exercise.repetitions * this.exercise.actions.length;
    if ((this.instructionCounter < exerciseDuration) && (!this.exerciseFinished)) {
      const action = this.exercise.actions[this.instructionCounter % this.exercise.actions.length];
      this.activeActionDetails = {
        started_at: Date.now(),
        ms_duration: action.duration * 1000
      }

      this.current_instruction_seconds_range = action.duration + 1
      // this.generalService.getVoices(action.instruction[this.language], this.language);
      if (this.playAudioInstruction && !this.playingWarningAudio) {
        this.mp3Player.playFromUrl(this.audioInstructionArray[this.instructionCounter % this.exercise.actions.length], () => { }, () => { }, true)
        this.playingInstructionAudio = true;
        setTimeout(() => {
          this.playingInstructionAudio = false;
        }, action.audio_length_milliseconds)
        this.playAudioInstruction = false;
      }

      delayX(1000, () => {
        const instructionDuration = action.duration;
        this.countdownTotal = instructionDuration;
        this.countdownNumber = instructionDuration;
        this.countdownPercentage = 0
        secondsCounter(instructionDuration, (v) => {
          this.beep('second');
          this.countdownNumber = v;
          this.countdownPercentage = 100 - ((this.countdownNumber / this.countdownTotal) * 100);
        }, (v) => {
          this.beep('break');
          this.countdownNumber = 0
          this.countdownPercentage = 0
          this.instructionCounter++;
          this.playAudioInstruction = true;
          this.total_seconds_passed += this.current_instruction_seconds_range;
          this.startExercise();

        })

      })

    } else {
      this.sets--;
      if (!this.sets) {
        this.stopExercise();
      } else {
        if (this.playAudioInstruction) {
          this.mp3Player.pause();
          this.mp3Player.playFromUrl(this.setBreakAudioUrl, () => { }, () => { }, true)
          this.playAudioInstruction = false;
        }
        this.instructionCounter = 0;
        this.countdown = true;
        this.countdownTotal = this.setBreakDuration;
        this.countdownNumber = this.setBreakDuration;
        this.countdownPercentage = 0
        secondsCounter(this.setBreakDuration, (timeleft) => {
          this.countdownNumber = timeleft;
          this.countdownPercentage = 0
          this.countdownPercentage = 100 - ((this.countdownNumber / this.countdownTotal) * 100);
        }, (v) => {
          this.beep('break');
          this.countdownNumber = 0
          this.countdownPercentage = 0
          this.countdown = false;
          this.playAudioInstruction = true;
          this.onPlay();
          this.startExercise();
        })
      }
    }
  }
  stopped = true
  stopExercise(notCompleted?: boolean) {
    this.stopped = true;
    clearInterval(this.countdownTimer);
    clearInterval(this.calculationsTimer);
    this.exerciseStarted = false;
    this.exerciseFinished = !notCompleted;

    clearInterval(this.bodyPixInterval);
    this.video.nativeElement.removeEventListener('play', this.onPlay);

    this.video.nativeElement.pause();
    if (this.video.nativeElement.srcObject) {
      (this.video.nativeElement.srcObject as MediaStream).getVideoTracks()[0].stop();
      this.video.nativeElement.srcObject = null;
    }

    let exerciseDuration = this.exercise.repetitions * this.exercise.actions.length;
    let exerciseErrorOccured: boolean = false;
    if (this.instructionCounter < exerciseDuration) {
      exerciseErrorOccured = true;
    }
    if (this.user.role === 'patient' && !this.resultsUploaded && !exerciseErrorOccured) {
      this.uploadExerciseResults();
    }
  }
  startWarningDuration(warning: any) {
    this.playWarning(warning)
    setTimeout(() => {
      let index = this.exerciseWarnings.findIndex(element => element.warning.display_name === warning.display_name);
      this.exerciseWarnings[index].display = false;
    }, 1000);
  }
  handleBreathingExerciseWarning = (warning) => {
    let obj = {
      warning: warning,
      display: false
    }


    let index = this.exerciseWarnings.findIndex(element => element.warning.display_name === warning.display_name);
    if (index == -1) {
      this.exerciseWarnings.push(obj)
      index = this.exerciseWarnings.length - 1;
    }
    //setShowWarningImage(true);
    if (warning.unit === 'boolean' && warning.value === true && !this.exerciseWarnings[index].display) {
      this.exerciseWarnings[index].display = true;
      this.startWarningDuration(warning);

    } else if (warning.unit === 'direction' && warning.value != 0 && !this.exerciseWarnings[index].display) {
      this.exerciseWarnings[index].warning = warning;
      this.exerciseWarnings[index].display = true;
      this.startWarningDuration(warning);
    }






  }
  calculateBreathe() {
    let instruction = this.exercise.actions[this.instructionCounter % this.exercise.actions.length].name;
    const requestData = {
      raw_data: this.exerciseData,
      type: this.exercise.category,
      category: this.exercise.category,
      name: this.exercise.name,
      instruction: instruction,
      uid: this.user.id
    }

    this.videoService.getCalculations(requestData).subscribe(data => {
      data = data['data'];
      if (data.hasOwnProperty('breathing_belly_result') && data.hasOwnProperty('breathing_chest_result')) {
        const tempData = data.breathing_belly_result;
        this.breathValue = this.breathValue + (tempData * 10);
        const tempChestData = data.breathing_chest_result;
        this.breathChestValue = this.breathChestValue + (tempChestData * 10);


        this.breathValue = this.breathValue > 100 ? 100 : this.breathValue < 0 ? 0 : this.breathValue;
        this.breathChestValue = this.breathChestValue > 100 ? 100 : this.breathChestValue < 0 ? 0 : this.breathChestValue;

        if (instruction.includes('inhale')) {
          this.correctBreathing = tempData > 0;
        } else if (instruction.includes('exhale')) {
          this.correctBreathing = tempData < 0;
        }
        for (const key of Object.keys(data)) {
          let value = data[key];
          if (key === 'warning') {
            for (const w of Object.keys(value)) {
              this.handleBreathingExerciseWarning(value[w])
            }
          }
        }

      }
      this.exerciseResults.push(data);
    }, (err) => { }, () => { })
  }


  nextClicked() {
    this.isSingleNextClick = true;
    setTimeout(() => {
      if (this.isSingleNextClick) {
        if (this.nextClickCounter < 1) {
          if (this.instructionVideoType === 'ai-trainer') {
            this.playInstructionVideo('exercise');
          } else {
            this.instructionVideo.nativeElement.pause();
            this.videoInstructionUrl = null;
            this.displayAITrainerInstructionVideo = false;
            this.initWebcam();
          }
          this.nextClickCounter++;
        } else {
          this.instructionVideo.nativeElement.pause();
          this.videoInstructionUrl = null;
          this.displayAITrainerInstructionVideo = false;
          this.initWebcam();
        }
      }
    }, 250);
  }

  preventDoubleClick() {
    this.isSingleNextClick = false;
    this.instructionVideo.nativeElement.pause();
    this.videoInstructionUrl = null;
    this.displayAITrainerInstructionVideo = false;
    this.initWebcam();
  }


  beep(input: string) {
    const baseuri = 'https://firebasestorage.googleapis.com/v0/b/breathment-production-bc2ad.appspot.com/o/sounds%2F'
    let src = ''
    if (input === 'second') {
      src = baseuri +
        'second-beep.wav?alt=media&token=5cca4e44-e975-428a-bf15-2afed2853095';
    } else if (input === 'break') {
      src = baseuri +
        'long-beep.wav?alt=media&token=998e675f-82e6-49d1-a277-0239c55be060';
    } else if (input === 'squatbeep') {
      src = baseuri +
        'good-bright-idea.mp3?alt=media&token=6ac220ca-356f-4afa-9e4a-91d38b799c9b';
    }
    this.beepPlayer.playFromUrl(src, () => { }, () => { })
  }

  getVideoDimensions() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    const video = this.video.nativeElement;
    const videoRatio = video.videoWidth / video.videoHeight;
    let width = video.offsetWidth, height = video.offsetHeight;
    const elementRatio = width / height;
    if (elementRatio > videoRatio) {
      this.timerPositionLeft = (width - (height * videoRatio)) / 2;

      width = height * videoRatio;
      this.videoDimensions = null;
      this.videoMarginBottom = null;
    } else {
      this.timerPositionTop = (height - (width / videoRatio)) / 2;

      height = width / videoRatio;
      this.videoMarginBottom = (video.offsetHeight - height) / 2;
      this.videoDimensions = height;
      if (this.exercise.initial_pose.margin === 'small_top') {
        this.videoDimensions = this.videoDimensions * 0.9;
      } else if (this.exercise.initial_pose.margin === 'top') {
        this.videoDimensions = this.videoDimensions * 0.8;
      }
    }
  }

  getExerciseGif(event, exercise) {
    if (exercise.category === 'breathing' || exercise.category === 'Breathing' || exercise.exercise?.category === 'breathing' || exercise.exercise?.category === 'Breathing') {
      event.target.src = '../../../assets/images/white.jpg'
    } else if (exercise.category === 'lower_body' || exercise.category === 'Lower body' || exercise.exercise?.category === 'lower_body' || exercise.exercise?.category === 'Lower body') {
      event.target.src = '../../../assets/images/white.jpg'
    } else {
      event.target.src = '../../../assets/images/white.jpg';
    }
  }

  playInstructionVideo(type: string, admin?: string) {
    let physioId: string, folder: string;
    if (admin) {
      physioId = admin;
    } else {
      if (this.user.role === 'physiotherapist') {
        physioId = this.user.id;
      } else {
        physioId = this.user.physiotherapist._id;
      }
    }
    this.beforeExerciseDisplay = false;
    this.displayAITrainerInstructionVideo = true;
    if (this.firstExercise || type === 'ai-trainer') {
      folder = 'introduction.mp4';
      type = 'ai-trainer';
      this.firstExercise = false;
    } else if (type === 'exercise') {
      folder = `${this.exercise.name}.mp4`;
      type = 'exercise';
    }
    this.instructionVideoType = type;
    this.videoService.getInstructionVideo(physioId, this.language, folder).subscribe(data => {
      this.videoInstructionUrl = data;
    },
      error => {
        if (error.code === 'storage/object-not-found' && !admin) {
          this.playInstructionVideo(type, 'breathment');
        } else {
          this.nextClicked();
        }
      });
  }



  getWarningImage($event, exercise) {
    this.showWarningImage = false;
  }

  getAudioInstructions() {
    this.generalService.getAudioInstructions(this.exercise.name, this.language, this.exercise.actions.length).subscribe(data => {
      this.audioInstructionArray = data;
    });
  }

  lastWarningTracker:WarningTracker = new WarningTracker();
  playWarning = async (warning: any) => {


    let { key } = getWarningKey(warning);
    if (!key) return;
    if (key === 'undefined-warning') {
      key = warning.message;
    }
    if (warning.key) key = warning.key;

    if (this.lastWarningTracker.shouldPlay(key))
    this.playLiveWarning(key)
  }


  getContext() {
    const canvas = this.c1.nativeElement;

    const imageFrame = this.video.nativeElement;

    imageFrame.width = imageFrame.videoWidth;
    imageFrame.height = imageFrame.videoHeight;

    canvas.width = imageFrame.videoWidth;
    canvas.height = imageFrame.videoHeight;

    const context = canvas.getContext('2d');
    context.translate(canvas.width, 0);
    context.scale(-1, 1);
    const handleImage = (handler) => {
      if (!handler) return;
      context.drawImage(imageFrame, 0, 0, imageFrame.width, imageFrame.height, 0, 0, imageFrame.width, imageFrame.width * (imageFrame.height / imageFrame.width));
      // canvas.toBlob(handler, 'image/jpeg');
      let b64 = canvas.toDataURL("image/jpeg");
      handler(b64.substring(23));
    }
    return { context, canvas, imageFrame, handleImage };
  }
  playLiveWarning(displayName) {
    if (this.playingInstructionAudio || this.playingWarningAudio) return;
    const keys = displayName.split(".");
    let warningText = ''
    if (keys.length > 1) {
      warningText = keys.reduce((obj, i) => {
        return obj[i]
      }, this.audioWarnings);
    } else {
      warningText = this.audioWarnings[displayName];
    }
    const requestData = { warning: warningText, language: this.language };
    this.generalService.getTextAudio(requestData).subscribe((resp) => {
      if (!this.stopped)
        this.mp3Player.playFromBase64(resp['data'], () => { }, () => { }, false)
    })
  }


}
