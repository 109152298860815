import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'searchFilter' })
export class FilterPipe implements PipeTransform {
  /**
   * Pipe filters the list of elements based on the search text provided
   *
   * @param items list of elements to search in
   * @param searchText search string
   * @returns list of elements filtered by search text or []
   */

  transform(
    items: any[],
    searchName: string,
    searchText: string,
    filterType?: string,
    sortingType?: string,
    arrowIconName?: string,
    arrowIconBirthdate?: string,
    arrowIconProgramStatus?: string,
    arrowIconIllness?: string,
    arrowIconLastActivityDate?: string,
    creationDate?: string
  ): any[] {
    if (!items) {
      return [];
    }

    if (sortingType === 'name') {
      if (arrowIconName === "fa fa-caret-up fa-2x mb-2") {
        items.sort((a, b) => {
          if (a['last_name'] && b['last_name']) {
            const valueA = a['last_name'].toLowerCase();
            const valueB = b['last_name'].toLowerCase();
            return valueB.localeCompare(valueA);
          }
          return 0;
        });

      } else {
        items.sort((a, b) => {
          if (a['last_name'] && b['last_name']) {
            const valueA = a['last_name'].toLowerCase();
            const valueB = b['last_name'].toLowerCase();
            return valueA.localeCompare(valueB);
          }
          return 0;
        });
      }
    } else if (sortingType === 'done_percentage') {
      if (arrowIconProgramStatus === "fa fa-caret-up fa-2x mb-2") {
        items.sort((a, b) => {
          if (a.program_summary.done_percentage && b.program_summary.done_percentage) {
            return b.program_summary.done_percentage - a.program_summary.done_percentage;
          }
          return 0;
        });
      } else {
        items.sort((a, b) => {
          if (a.program_summary.done_percentage && b.program_summary.done_percentage) {
            return a.program_summary.done_percentage - b.program_summary.done_percentage;
          }
          return 0;
        });
      }
    } else if (sortingType === 'illness') {
      if (arrowIconIllness === "fa fa-caret-up fa-2x mb-2") {
        items.sort((a, b) => {
          if (a['contact_reason'] && b['contact_reason']) {
            const valueA = a['contact_reason'].toLowerCase();
            const valueB = b['contact_reason'].toLowerCase();
            return valueB.localeCompare(valueA);
          }
          return 0;
        });
      } else {
        items.sort((a, b) => {
          if (a['contact_reason'] && b['contact_reason']) {
            const valueA = a['contact_reason'].toLowerCase();
            const valueB = b['contact_reason'].toLowerCase();
            return valueA.localeCompare(valueB);
          }
          return 0;
        });
      }
    } else if (sortingType === 'birthdate') {
      if (arrowIconBirthdate === "fa fa-caret-up fa-2x mb-2") {
        items.sort((a, b) => {
          if (a.birthdate && b.birthdate) {
            return b.birthdate - a.birthdate;
          }
          return 0;
        });
      } else {
        items.sort((a, b) => {
          if (a.birthdate && b.birthdate) {
            return a.birthdate - b.birthdate;
          }
          return 0;
        });
      }
    } else if (sortingType === 'last_activity_date') {
      if (arrowIconLastActivityDate === "fa fa-caret-up fa-2x mb-2") {
        items.sort((a, b) => {
          if (a.last_activity_date && b.last_activity_date) {
            return b.last_activity_date - a.last_activity_date;
          }
          return 0;
        });
      } else {
        items.sort((a, b) => {
          if (a.last_activity_date && b.last_activity_date) {
            return a.last_activity_date - b.last_activity_date;
          }
          return 0;
        });
      }
    } else if (sortingType === 'created_at') {
      if (creationDate === "fa fa-caret-up fa-2x mb-2") {
        items.sort((a, b) => {
          if (a['created_at'] && b['created_at']) {
            return b.created_at - a.created_at;
          }
          return 0;
        });
      } else {
        items.sort((a, b) => {
          if (a['created_at'] && b['created_at']) {
            return a.created_at - b.created_at;
          }
          return 0;
        });
      }
    }

    if (filterType === 'contactReason') {
      filterType = 'illness';
    }

    if (searchName === 'patient') {
      if (!searchText) {
        return items;
      }
      searchText = searchText.toLocaleLowerCase();
      return items.filter(it => {
        if (filterType === 'none') {
          return (it['first_name'].toLocaleLowerCase() + ' ' + it['last_name'].toLocaleLowerCase()).includes(searchText) || (it['contact_reason'] && it['contact_reason'].toLocaleLowerCase().includes(searchText)) || (it['illness'] && it['illness'].toLocaleLowerCase().includes(searchText));

        }
        else if (filterType === 'illness') {
          return (it['contact_reason'] && it['contact_reason'].toLocaleLowerCase().includes(searchText)) || (it['illness'] && it['illness'].toLocaleLowerCase().includes(searchText))
        }

        return it[filterType].toLocaleLowerCase().includes(searchText);
      });

    }


    else if (searchName === 'exercise') {
      if (filterType !== 'none') {
        if (filterType == 'sit' || filterType == 'stand') {
          items = items.filter(it => it['initial_pose']['pose'].split('_')[1] === filterType);
        } else if (filterType == 'supine') {
          items = items.filter(it => it['initial_pose']['pose'] === 'supine');
        } else if (filterType == 'breathing') {
          items = items.filter(it => ['breathing', 'breathing_time', 'relaxation'].includes(it['category']));
        } else {
          items = items.filter(it => it['category'] === filterType);
        }
        if (searchText) {
          searchText = searchText.toLocaleLowerCase();
          return items.filter(it => {
            return (
              it['display_name']['en'].toLocaleLowerCase().includes(searchText) ||
              it['display_name']['de'].toLocaleLowerCase().includes(searchText) ||
              it['display_name']['tr'].toLocaleLowerCase().includes(searchText) ||
              it['display_name']['it'].toLocaleLowerCase().includes(searchText)
            ) ||
              it['category'].toLocaleLowerCase().includes(searchText);
          });
        }
        return items;
      } else {
        if (searchText) {
          searchText = searchText.toLocaleLowerCase();
          return items.filter(it => {
            return (
              it['display_name']['en'].toLocaleLowerCase().includes(searchText) ||
              it['display_name']['de'].toLocaleLowerCase().includes(searchText) ||
              it['display_name']['tr'].toLocaleLowerCase().includes(searchText) ||
              it['display_name']['it'].toLocaleLowerCase().includes(searchText)
            ) ||
              it['category'].toLocaleLowerCase().includes(searchText);
          });
        }
      }

      return items;
    }

  }

}
