import {
  Component,
  OnInit,
  NgZone,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

// Services
import { VideoService } from '../../../services/video.service';
import { ExerciseService } from '../../../services/exercise/exercise.service';
import { GeneralService } from '../../../services/general/general.service';
import { TranslateService } from '@ngx-translate/core';
import { closeTheCamera, convertDataURIToBinary, delayX, getWarningKey, secondsCounter } from '@helpers/helper';
import { IUserProfile } from 'src/app/resolvers';
import { AuthService } from '@services/auth.service';
import { SoundPlayer,  WarningTracker } from '@helpers/sound-player';

@Component({
  selector: 'app-relaxation-exercise',
  templateUrl: './relaxation-exercise.component.html',
  styleUrls: ['./relaxation-exercise.component.css'],
})
export class RelaxationExerciseComponent implements OnInit {
  getRemainingRound(feedback: any) {
    if (this.exercise.name === 'sit2stand_test') {
      this.controlSquatVoice = feedback.total;
      if (this.controlSquatVoice > this.controlSquatİndex) {
        this.beep('squatbeep');
        this.controlSquatİndex = this.controlSquatİndex + 1;
      }
      return feedback.total;
    }
    if (this.exercise.name === 'squat') {
      this.controlSquatVoice = feedback.total;
      if (this.controlSquatVoice > this.controlSquatİndex) {
        this.beep('squatbeep');
        this.controlSquatİndex = this.controlSquatİndex + 1;
      }
      return feedback.action_count - feedback.total;
    }
    if (feedback.action_count) {
      return feedback.action_count - (feedback.value ?? feedback.total);
    }
    return (
      ((this.totalSets - this.remainingSets) * this.exercise.repetitions) + this.exercise.repetitions -
      (feedback.total ? feedback.total : feedback.value)
    );
  }

  @ViewChild('cameraVideo', { static: true })
  video: ElementRef<HTMLVideoElement>;
  @ViewChild('mainInstructionVideo', { static: true })
  instructionVideo: ElementRef<HTMLVideoElement>;
  @ViewChild('c1', { static: true }) private c1: any;
  @Input() exercise: any;

  @Input() exerciseDate: string;
  @Input() firstExercise: boolean;
  @Output() closeExerciseViewEvent = new EventEmitter<boolean>();
  @ViewChild('beepAudioElement') beepAudio: ElementRef<HTMLAudioElement>;
  @ViewChild('instructionAudioElement')
  instructionAudio: ElementRef<HTMLAudioElement>;
  @ViewChild('warningAudioElement') warningAudio: ElementRef<HTMLAudioElement>;

  public calculationStartValue: number = 0;
  public controlSquatVoice = 0;
  public controlSquatİndex = 0;
  user: IUserProfile;
  public dataLoaded = false;
  public videoDimensions: number;
  public videoMarginBottom: number;
  public timerPositionLeft: number;
  public timerPositionTop: number;
  public playAudioInstruction: boolean = true;
  public audioInstructionArray: any[] = [];
  public playWarningAudio: boolean = true;
  public raiseArmAudio: boolean = true;
  public warningAudioSource: any;

  public countdown: boolean = false;
  public countdownNumber: number;
  public countdownTotal: number;
  public countdownPercentage: number;
  public showTimer: boolean = false;
  public exerciseStarted: boolean = false;
  public exerciseFinished: boolean = false;
  public countdownTimer: any;
  public isProcessing: boolean = false;
  public bodyPixInterval: any = null;

  public beforeExerciseDisplay: boolean = true;
  public serverError: boolean = false;

  public displayAITrainerInstructionVideo: boolean = null;
  public videoInstructionUrl: any;
  public nextClickCounter: number = 0;
  public isSingleNextClick: boolean = true;
  public instructionVideoType: string;

  public resultsUploaded: boolean = false;

  public exerciseData: any[] = [];
  public previousKeyPoints: any[] = [];

  public exerciseResults: any[] = [];
  public exerciseWarnings: any[] = [];
  public exerciseCheckpoints: any[] = [];
  public checkpointImage: string = null;
  public checkpointImageMargin: boolean;

  public correctSquat;
  public totalSquat;
  public confidence: any;
  public instructionCounter: number = 0;

  public poseWarning: boolean = false;
  public gifUrl: string = ' ';
  public viewDate: Date = new Date();
  public gifUrls: any[] = [];
  public dataArr: string[] = [];
  public pauseCalculation: boolean = false;
  public showWarningImage: boolean = true;
  public warningDurationInterval: any = null;
  public exerciseName: string = '';
  public language: string;

  private initialPoseCounter: number = 0;
  startGestureTriggered: boolean = false;

  public totalSets: number = 1;
  public remainingSets: number = 1;
  public setBreakActive: boolean = false;
  public setBreakDuration: number = 0;
  public setBreakAudioUrl: string = '';

  private audioInstructionBuffer: string;

  public playingInstructionAudio = false;
  public playingWarningAudio = false;
  public audioWarnings = null;

  public longTermVideo: any = null;

  constructor(
    private ngZone: NgZone,
    private videoService: VideoService,
    private exerciseService: ExerciseService,
    private generalService: GeneralService,
    private translate: TranslateService,
    private authService: AuthService,

  ) {
    this.user = this.authService.me
    // this.language = this.user.locale;
    this.language = this.translate.currentLang;
    this.getAudioWarningTranslations();
    this.translate.onLangChange.subscribe(value => {
      this.translate.use(value.lang);
      this.language = value.lang;
      this.getAudioWarningTranslations();
    });

  }
  beepPlayer: SoundPlayer;
  mp3Player: SoundPlayer;

  ngOnInit(): void {
    this.beepPlayer = new SoundPlayer('beepAudioPlayer')
    this.mp3Player = new SoundPlayer('mp3AudioPlayer')

    this.totalSets = this.exercise.sets;
    this.remainingSets = this.exercise.sets;
    if (this.exercise.set_break?.active) {
      this.setBreakDuration = this.exercise.set_break.duration;
    }
    this.enableAutoTTS();
    this.getAudioInstructionsFromServer(this.exercise);
    setTimeout(() => {
      this.dataLoaded = true;
    }, 700);
    // this.getGifLinks();
    this.generalService.getLongTermVideo().subscribe(data => {
      this.longTermVideo = data;
    });
  }

  // getGifLinks(): string | undefined {
  //   this.exerciseName = this.exercise.name + '.gif';
  //   if (this.exerciseName === 'sit2stand_test.gif') {
  //     this.exerciseName = 'squat.gif';
  //   }
  //   if (
  //     this.exerciseName === 'diaphragm.gif' ||
  //     this.exerciseName === 'lip.gif'
  //   ) {
  //     this.exerciseName = 'breath.gif';
  //   }
  //   this.generalService.getGifByName(this.exerciseName).subscribe((url) => {
  //     this.gifUrl = url;
  //   });
  //   return this.gifUrl;
  // }

  getAudioWarningTranslations() {
    this.generalService.getAudioTranslations({ lang: this.language }).subscribe((res) => {
      this.audioWarnings = res?.data?.audioWarnings;
    },
      (err) => {
        console.error('error retrieving audio translations: ', err);
      })
  }

  // initWebcam() {
  //   if (navigator.mediaDevices) {
  //     navigator.mediaDevices
  //       .getUserMedia({ video: true })
  //       .then((stream) => {
  //         this.video.nativeElement.srcObject = stream;
  //         this.video.nativeElement.addEventListener('play', this.onPlay);
  //       })
  //       .catch((e) => console.error(e));
  //   }
  // }

  // getContext() {
  //   const canvas = this.c1.nativeElement;

  //   const imageFrame = this.video.nativeElement;

  //   imageFrame.width = imageFrame.videoWidth;
  //   imageFrame.height = imageFrame.videoHeight;

  //   canvas.width = imageFrame.videoWidth;
  //   canvas.height = imageFrame.videoHeight;

  //   const context = canvas.getContext('2d');
  //   context.translate(canvas.width, 0);
  //   context.scale(-1, 1);
  //   const handleImage = (handler) => {
  //     if (!handler) return;
  //     context.drawImage(
  //       imageFrame,
  //       0,
  //       0,
  //       imageFrame.width,
  //       imageFrame.height,
  //       0,
  //       0,
  //       imageFrame.width,
  //       imageFrame.width * (imageFrame.height / imageFrame.width)
  //     );
  //     // canvas.toBlob(handler, 'image/jpeg');
  //     let b64 = canvas.toDataURL('image/jpeg');
  //     handler(b64.substring(23));
  //   };
  //   return { context, canvas, imageFrame, handleImage };
  // }
  // uploadImage = (file) => {
  //   if (file) {
  //     this.ngZone.run(() => {
  //       this.isProcessing = true;
  //       const { handleImage } = this.getContext();

  //       if (this.playingInstructionAudio) {
  //         delayX(50, () => handleImage(this.uploadImage));
  //         return;
  //       }

  //       const action =
  //         this.exercise.actions[
  //         this.instructionCounter % this.exercise.actions.length
  //         ];
  //       this.pauseCalculation = action.name === 'break';

  //       if (this.pauseCalculation) {
  //         this.calculationStartValue = 0;
  //         delayX(50, () => handleImage(this.uploadImage));
  //         return;
  //       }

  //       const requestData = {
  //         image: file,
  //         category: this.exercise.category,
  //         name: this.exercise.name,
  //         prev_key_points: JSON.stringify(this.previousKeyPoints),
  //         uid: this.user.id,
  //         base64: true,
  //         vertical: false,
  //         iOS: true,
  //       };
  //       if (this.confidence) {
  //         requestData['confidence_mean'] = this.confidence;
  //       }
  //       this.videoService.sendFrame(requestData).subscribe(
  //         (data) => {
  //           if (data.warning) {
  //             this.playWarning(data.warning)
  //           }
  //           if (data['data']) {
  //             this.poseWarning = false;
  //             if (data['data'].prev_key_points.length > 0) {
  //               this.previousKeyPoints = data['data'].prev_key_points;
  //             }
  //             if (data['data'].confidence_mean) {
  //               this.confidence = data['data'].confidence_mean;
  //             }
  //             let rawData = data['data'];
  //             rawData['timestamp'] = Date.now();
  //             const action =
  //               this.exercise.actions[
  //               this.instructionCounter % this.exercise.actions.length
  //               ];
  //             if (action.silhouette_name) {
  //               rawData['silhouette_name'] = action.silhouette_name;
  //             }

  //             delete rawData.prev_key_points;
  //             // delete rawData.confidence_mean;
  //             this.exerciseData.push(rawData);
  //             this.calculationStartValue++;
  //             if (
  //               this.exerciseData.length > 3 &&
  //               this.calculationStartValue > 3
  //             ) {
  //               this.calculateRealTime();
  //             }
  //           }
  //         },
  //         (err) => {
  //           // if (err.error.error.message === 'keyPointMissing') {
  //           //   this.poseWarning = true;
  //           // }
  //           handleImage(this.uploadImage);
  //         },
  //         () => {
  //           handleImage(this.uploadImage);
  //         }
  //       );
  //     });
  //   }
  // };

  // calculateRealTime() {
  //   const requestData = {
  //     category: this.exercise.category,
  //     raw_data: this.exerciseData,
  //     name: this.exercise.name,
  //     uid: this.user.id,
  //   };

  //   this.videoService.getCalculations(requestData).subscribe(
  //     (returnData) => {
  //       this.exerciseResults = [];
  //       // this.exerciseWarnings = [];
  //       // this.exerciseCheckpoints = [];
  //       let sessionData = returnData['data'];
  //       const action =
  //         this.exercise.actions[
  //         this.instructionCounter % this.exercise.actions.length
  //         ];

  //       if (action.name === 'break') {
  //         this.checkpointImage = null;
  //         if (this.exerciseCheckpoints.length > 0) {
  //           this.exerciseCheckpoints = this.exerciseCheckpoints.map(
  //             (checkpoint) => {
  //               return { ...checkpoint, value: false };
  //             }
  //           );
  //         }
  //       }

  //       for (const key of Object.keys(sessionData)) {
  //         let value = sessionData[key];
  //         value.action_name = key;

  //         if (value.unit === 'count') {
  //           const act = this.exercise.actions.find(
  //             (a) => a.name === value.action_name && a.count
  //           );
  //           if (act) {
  //             value.action_count = act.count * this.exercise.repetitions;
  //           }
  //           this.exerciseResults.push(value);
  //         }
  //         if (key === 'warning') {
  //           this.handleWarnings(value)

  //           for (const prop of Object.keys(value)) {
  //             if (prop !== 'action_name') {
  //               this.handlePhysicalExerciseWarning(prop, value[prop])
  //             }
  //           }
  //         }
  //         if (value.checkpoints) {
  //           for (const _key of Object.keys(value.checkpoints)) {
  //             let checkpoint = {
  //               name: _key,
  //               value: value.checkpoints[_key],
  //             };

  //             const position = this.exerciseCheckpoints.findIndex(
  //               (e) => e.name === checkpoint.name
  //             );
  //             if (position === -1) {
  //               this.exerciseCheckpoints.push(checkpoint);
  //             } else {
  //               this.exerciseCheckpoints[position].value = checkpoint.value;
  //             }

  //             // NEW

  //             if (checkpoint.name === action.silhouette_name) {
  //               // this.isCheckpointMarginNeeded();
  //               let exerciseDuration =
  //                 this.exercise.repetitions * this.exercise.actions.length;

  //               if (
  //                 !this.setBreakActive &&
  //                 action.name !== 'break' &&
  //                 this.instructionCounter < exerciseDuration
  //               ) {
  //                 this.checkpointImage = action.silhouette_name;
  //                 if (checkpoint.value) {
  //                   this.instructionCounter++;
  //                   this.playAudioInstruction = true;
  //                   if (!this.pauseCalculation) {
  //                     delayX(1 * 1000, () => {
  //                       this.startExercise();
  //                     });
  //                   }
  //                 }
  //               }
  //             }

  //             // NEW
  //           }
  //         }

  //         for (const _key of Object.keys(value)) {
  //           let result = value[_key];
  //           if (result.unit === 'count') {
  //             this.exerciseResults.push(result);
  //           }
  //         }
  //       }
  //     },
  //     (error) => {

  //     }
  //   );
  // }
  handlePhysicalExerciseWarning = (prop: string, warning: any) => {

    const prefix = this.exercise.name.indexOf('cosmic') > -1 ? 'cosmic' :
      this.exercise.name.indexOf('outer_space') > -1 ? 'outer_space' : null;
    if (warning.unit === 'direction' && warning.value != 'neutral' && prefix != null) {
      const key = `${prefix}.${prop}_${warning.value}`;
      this.playWarning({ key });
      return;
    }

    if (warning.unit === 'boolean' && warning.value === true) {
      this.playWarning(warning);
      return;
    }
    if (warning.unit === 'direction' && warning.value != 'neutral') {
      this.playWarning(warning);
      return;
    }
  }
  handleWarnings(value: any) {

    for (const [_, warning] of Object.entries(value).filter((e: any) => {
      e[0] != 'action_name' &&
        ((e[1].unit == 'boolean' && e[1].value) || (e[1].unit == 'direction' && e[1].value != 'neutral'))
    }) as Array<[string, any]>) {
      let obj = {
        warning: warning,
        display: false,
      };
      let index = this.exerciseWarnings.findIndex(
        (element) =>
          element?.warning?.display_name === warning?.display_name
      );
      if (index < 0) {
        this.exerciseWarnings.push(obj);
      } else {
        if (
          warning.unit === 'boolean' &&
          warning.value === true &&
          !this.exerciseWarnings[index].display
        ) {
          this.exerciseWarnings[index].display = true;
          this.startWarningDuration(warning);
        } else if (
          warning.unit === 'direction' &&
          warning.value !== 'neutral' &&
          !this.exerciseWarnings[index].display
        ) {
          this.exerciseWarnings[index].warning = warning;
          this.exerciseWarnings[index].display = true;
          this.startWarningDuration(warning);
        }
      }

      this.playLiveWarning(warning.display_name);

    }

  }

  // uploadExerciseResults() {
  //   const requestData = {
  //     date: this.exerciseDate,
  //     exercise_id: this.exercise.exercise_id,
  //     raw_data: this.exerciseData,
  //     processed_data: [],
  //     confidence_mean: this.confidence,
  //   };
  //   this.resultsUploaded = true;
  //   this.exerciseService.uploadResults(requestData).subscribe((data) => { });
  // }
  // search_for_start_gesture = 0;
  // initialPose = false;

  // checkInitialPose = (file) => {
  //   if (file) {
  //     this.ngZone.run(() => {
  //       if (!this.videoDimensions) {
  //         this.getVideoDimensions();
  //       }

  //       // Run the code here
  //       this.isProcessing = true;
  //       const { handleImage } = this.getContext();

  //       const requestData = {
  //         image: file,
  //         category: this.exercise.category,
  //         name: this.exercise.name,
  //         uid: this.user.id,
  //         base64: true,
  //         vertical: false,
  //         iOS: true,
  //       };

  //       this.videoService.checkPose(requestData).subscribe(
  //         (data) => {
  //           this.initialPose = data['initial_pose'];
  //           const warning = data['warning'];
  //           if (warning && this.playWarningAudio) {

  //             this.playWarning(warning);
  //           }
  //           if (!this.initialPose) {
  //             this.initialPoseCounter = 0;
  //             handleImage(this.checkInitialPose);
  //             return;
  //           }
  //           this.initialPoseCounter++;
  //           if (this.initialPoseCounter === 2) {
  //             handleImage(null)
  //             this.mp3Player.pause();
  //             this.initialPoseCounter++;
  //             if (this.raiseArmAudio) {
  //               this.playWarning({ message: 'raiseArmToStart' });
  //             }
  //             handleImage(this.checkStartGesture);
  //           } else handleImage(this.checkInitialPose);
  //         },
  //         (err) => {
  //           if (err.status === 0) {
  //             this.serverError = true;
  //             this.stopExercise();
  //           }
  //         },
  //         () => {


  //         }
  //       );
  //     });
  //   }
  // };

  // checkStartGesture = (file) => {
  //   if (file) {
  //     this.ngZone.run(() => {
  //       // Run the code here

  //       this.isProcessing = true;
  //       const { handleImage } = this.getContext();

  //       const requestData = {
  //         image: file,
  //         category: this.exercise.category,
  //         name: this.exercise.name,
  //         uid: this.user.id,
  //         base64: true,
  //         vertical: false,
  //       };

  //       this.videoService.checkStartGesture(requestData).subscribe(
  //         (data) => {

  //           if (data['exercise_start'] === true) {
  //             handleImage(null);
  //             this.mp3Player.pause();

  //             this.startGestureTriggered = true;

  //             this.startCountdown();

  //           } else {
  //             this.search_for_start_gesture++;
  //             if (this.search_for_start_gesture > 100) {
  //               this.search_for_start_gesture = 0
  //               this.initialPoseCounter = 0;
  //               handleImage(this.checkInitialPose);
  //             } else {
  //               handleImage(this.checkStartGesture);

  //             }
  //           }
  //         },
  //         (err) => {

  //         }
  //       );
  //     });
  //   }
  // };

  nextClicked() {
    this.isSingleNextClick = true;
    setTimeout(() => {
      if (this.isSingleNextClick) {
        if (this.nextClickCounter < 1) {
          if (this.instructionVideoType === 'ai-trainer') {
            this.playInstructionVideo('exercise');
          } else {
            this.instructionVideo.nativeElement.pause();
            this.videoInstructionUrl = null;
            this.displayAITrainerInstructionVideo = false;
            this.startCountdown();
            // this.initWebcam();
          }
          this.nextClickCounter++;
        } else {
          this.instructionVideo.nativeElement.pause();
          this.videoInstructionUrl = null;
          this.displayAITrainerInstructionVideo = false;
          this.startCountdown();
          // this.initWebcam();
        }
      }
    }, 250);
  }

  // helper functions

  preventDoubleClick() {
    this.isSingleNextClick = false;
    this.instructionVideo.nativeElement.pause();
    this.videoInstructionUrl = null;
    this.displayAITrainerInstructionVideo = false;
    // this.initWebcam();
  }

  // onPlay = () => {
  //   this.stopped = false;
  //   const { handleImage, context, imageFrame } = this.getContext();
  //   if (this.exerciseStarted) {
  //     handleImage(this.uploadImage);
  //   } else if (!this.exerciseFinished) {

  //     this.playWarning({ message: 'silhouettePosition' });
  //     handleImage(this.checkInitialPose);
  //   } else {
  //     clearInterval(this.bodyPixInterval);
  //     this.bodyPixInterval = setInterval(() => {
  //       if (!this.isProcessing) {
  //         context.drawImage(
  //           imageFrame,
  //           0,
  //           0,
  //           imageFrame.width,
  //           imageFrame.height,
  //           0,
  //           0,
  //           imageFrame.width,
  //           imageFrame.width * (imageFrame.height / imageFrame.width)
  //         );
  //       }
  //     }, 100);
  //   }
  // };

  startCountdown() {
    this.countdown = true;
    this.countdownTotal = 5;
    this.countdownNumber = 5;
    this.showTimer = true;
    this.countdownPercentage = 0;
    delayX(10, () => {
      secondsCounter(
        5,
        (v) => {
          if (this.countdown) {
            this.beep('second');
          }
          this.countdownNumber = v;
          this.countdownPercentage =
            100 - (this.countdownNumber / this.countdownTotal) * 100;
        },
        (v) => {
          this.beep('break');
          this.countdown = false;
          this.exerciseStarted = true;
          this.showTimer = false;
          // this.onPlay();

          setTimeout(() => {
            this.startExercise();
          }, 1000)

        }
      );
    });
  }

  startExercise() {
    // let exerciseDuration = this.exercise.actions[0].duration;

    let exerciseDuration =
      this.exercise.repetitions * this.exercise.actions.length;

    if (this.instructionCounter < exerciseDuration && !this.exerciseFinished) {

      const action =
        this.exercise.actions[
        this.instructionCounter % this.exercise.actions.length
        ];

      this.pauseCalculation = action.name === 'break';

      if (this.playAudioInstruction && !this.playingWarningAudio) {
        // this.generalService.getVoices(action.instruction[this.language], this.language);
        if (this.instructionCounter < exerciseDuration) {
          this.playAudioInstructionSound(
            this.instructionCounter % this.exercise.actions.length
          );
          this.playingInstructionAudio = true;
          setTimeout(() => {
            this.playingInstructionAudio = false;
          }, action.audio_length_milliseconds)
          this.playAudioInstruction = false;
        } else {
          this.stopExercise();
        }
      }

      if (action.duration) {
        this.countdownTotal = action.duration;
        this.showTimer = true;
        this.countdownNumber = action.duration;
        this.countdownPercentage = 100;

        secondsCounter(
          action.duration,
          (v) => {
            if (action.duration === 2) {
              this.beep('break');
            }
            this.countdownNumber = v;
            this.countdownPercentage =
              100 - (this.countdownNumber / this.countdownTotal) * 100;
          },
          (v) => {
            this.countdownNumber = 0;
            this.countdownPercentage = 0;
            if (
              action.hasOwnProperty('display_half') &&
              action.display_half === false
            ) {
              action.display_half = true;
            }
            this.pauseCalculation = false;
            this.instructionCounter++;
            this.playAudioInstruction = true;
            this.showTimer = false;
            // this.continueExercise();
            this.startExercise();
          }
        );
      } else if (action.count) {
        let iteration =
          this.instructionCounter / this.exercise.actions.length + 1;
        if (this.exerciseResults.length > 0) {
          if (iteration <= this.exercise.repetitions) {
            for (const feedback of this.exerciseResults) {
              if (feedback.action_name === action.name) {
                // feedback.action_count = action.count;
                if (
                  feedback.total &&
                  feedback.total >= action.count * iteration
                ) {
                  this.instructionCounter++;
                  this.playAudioInstruction = true;
                } else if (feedback.value >= action.count * iteration) {
                  this.instructionCounter++;
                  this.playAudioInstruction = true;
                }
              }
            }
          }
        }
        if (!this.pauseCalculation) {
          delayX(1 * 1000, () => {
            this.startExercise();
          });
        }
      }
    } else {
      this.remainingSets--;
      if (!this.remainingSets) {
        this.stopExercise();
      } else {
        this.setBreakActive = true;
        this.checkpointImage = null;
        this.mp3Player.playFromUrl(this.setBreakAudioUrl, () => { }, () => { }, true)

        this.instructionCounter = 0;
        this.countdown = true;
        this.countdownTotal = this.setBreakDuration;
        this.countdownNumber = this.setBreakDuration;
        this.countdownPercentage = 0
        secondsCounter(this.setBreakDuration, (timeleft) => {
          this.countdownNumber = timeleft;
          this.countdownPercentage = 0
          this.countdownPercentage = 100 - ((this.countdownNumber / this.countdownTotal) * 100);
        }, (v) => {
          this.beep('break');
          this.countdownNumber = 0
          this.countdownPercentage = 0
          this.countdown = false;
          this.playAudioInstruction = true;
          this.startExercise();
          this.setBreakActive = false;
        })
      }
    }
  }

  lastWarningTracker:WarningTracker = new WarningTracker()
  playWarning = async (warning: any) => {

    let { key } = getWarningKey(warning);
    if (!key) return;
    if (key === 'undefined-warning') {
      key = warning.message;
    }
    if (warning.key) key = warning.key;
    if (this.lastWarningTracker.shouldPlay(key))
      this.playLiveWarning(key)
  }
  stopExercise() {
    this.stopped = true;
    clearInterval(this.countdownTimer);
    this.exerciseStarted = false;
    this.exerciseFinished = true;

    clearInterval(this.bodyPixInterval);
    // this.video.nativeElement.removeEventListener('play', this.onPlay);

    // this.video.nativeElement.pause();
    // if (this.video.nativeElement.srcObject) {
    //   (this.video.nativeElement.srcObject as MediaStream)
    //     .getVideoTracks()[0]
    //     .stop();
    //   this.video.nativeElement.srcObject = null;
    // }
    let exerciseDuration =
      this.exercise.repetitions * this.exercise.actions.length;
    let exerciseErrorOccured: boolean =
      this.instructionCounter < exerciseDuration;

    if (
      this.user.role === 'patient' &&
      !this.resultsUploaded &&
      !exerciseErrorOccured
    ) {
      // this.uploadExerciseResults();
    }
    this.totalSets = 1;
    this.remainingSets = 1;
    this.setBreakDuration = 0;
  }

  closeExerciseView() {
    if (this.exerciseStarted) {
      this.generalService.stop();
      this.stopExercise();
    }
    this.closeExerciseViewEvent.emit(true);
    this.controlSquatVoice = 0;
    this.controlSquatİndex = 0;
    // closeTheCamera.apply(this)
  }

  beep(input: string) {
    const baseuri = 'https://firebasestorage.googleapis.com/v0/b/breathment-production-bc2ad.appspot.com/o/sounds%2F'
    let src = ''
    if (input === 'second') {
      src = baseuri +
        'second-beep.wav?alt=media&token=5cca4e44-e975-428a-bf15-2afed2853095';
    } else if (input === 'break') {
      src = baseuri +
        'long-beep.wav?alt=media&token=998e675f-82e6-49d1-a277-0239c55be060';
    } else if (input === 'squatbeep') {
      src = baseuri +
        'good-bright-idea.mp3?alt=media&token=6ac220ca-356f-4afa-9e4a-91d38b799c9b';
    }
    this.beepPlayer.playFromUrl(src, () => { }, () => { })
  }

  playAudioInstructionSound(index: number) {
    this.mp3Player.playFromUrl(this.audioInstructionArray[index], () => { }, () => { }, true)
  }
  stopped = false
  playLiveWarning(displayName) {

    if (this.playingInstructionAudio || this.playingWarningAudio) return;
    const keys = displayName.split(".");
    let warningText = ''
    if (keys.length > 1) {
      warningText = keys.reduce((obj, i) => {
        return obj[i]
      }, this.audioWarnings);
    } else {
      warningText = this.audioWarnings[displayName];
    }
    const requestData = { warning: warningText, language: this.language };
    this.generalService.getTextAudio(requestData).subscribe((resp) => {
      if (!this.stopped)
        this.mp3Player.playFromBase64(resp['data'], () => { }, () => { }, false)
    })
  }

  // getVideoDimensions() {
  //   let vh = window.innerHeight * 0.01;
  //   document.documentElement.style.setProperty('--vh', `${vh}px`);

  //   const video = this.video.nativeElement;
  //   const videoRatio = video.videoWidth / video.videoHeight;
  //   let width = video.offsetWidth,
  //     height = video.offsetHeight;
  //   const elementRatio = width / height;

  //   if (elementRatio > videoRatio) {
  //     this.timerPositionLeft = (width - height * videoRatio) / 2;

  //     width = height * videoRatio;
  //     this.videoDimensions = null;
  //     this.videoMarginBottom = null;
  //   } else {
  //     this.timerPositionTop = (height - width / videoRatio) / 2;

  //     height = width / videoRatio;
  //     this.videoMarginBottom = (video.offsetHeight - height) / 2;
  //     this.videoDimensions = height;
  //     if (this.exercise.initial_pose.margin === 'small_top') {
  //       this.videoDimensions = this.videoDimensions * 0.9;
  //     } else if (this.exercise.initial_pose.margin === 'top') {
  //       this.videoDimensions = this.videoDimensions * 0.7;
  //     }
  //   }
  // }

  getWarningImage($event, exercise) {
    this.showWarningImage = false;
  }

  startWarningDuration(warning: any) {
    this.playWarning(warning)
    setTimeout(() => {
      let index = this.exerciseWarnings.findIndex(
        (element) => element.warning.display_name === warning.display_name
      );
      this.exerciseWarnings[index].display = false;
    }, 1000);
  }

  enableAutoTTS() {
    if (typeof window === 'undefined') {
      return;
    }
    const isiOS =
      navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (!isiOS) {
      return;
    }
    const simulateSpeech = () => {
      const lecture = new SpeechSynthesisUtterance('hello');
      lecture.volume = 0;
      speechSynthesis.speak(lecture);
      document.removeEventListener('click', simulateSpeech);
    };

    document.addEventListener('click', simulateSpeech);
  }

  playInstructionVideo(type: string, admin?: string) {

    let physioId: string, folder: string;
    if (admin) {
      physioId = admin;
    } else {
      if (this.user.role === 'physiotherapist') {
        physioId = this.user.id;
      } else {
        physioId = this.user.physiotherapist._id;
      }
    }
    this.beforeExerciseDisplay = false;
    this.displayAITrainerInstructionVideo = true;
    if (this.firstExercise || type === 'ai-trainer') {
      folder = 'introduction.mp4';
      type = 'ai-trainer';
      this.firstExercise = false;
    } else if (type === 'exercise') {
      folder = `${this.exercise.name}.mp4`;
      type = 'exercise';
    }
    this.instructionVideoType = type;
    this.videoService
      .getInstructionVideo(physioId, this.language, folder)
      .subscribe(
        (data) => {
          this.videoInstructionUrl = data;
        },
        (error) => {
          if (error.code === 'storage/object-not-found' && !admin) {
            this.playInstructionVideo(type, 'breathment');
          } else {
            this.nextClicked();
          }
        }
      );
  }

  getAudioInstructions() {
    this.generalService
      .getAudioInstructions(
        this.exercise.name,
        this.language,
        this.exercise.actions.length
      )
      .subscribe((data) => {
        this.audioInstructionArray = data;
      });
  }

  async getAudioInstructionsFromServer(exercise: any) {
    for (let i = 0; i < exercise.actions.length; i++) {
      const instruction = exercise.actions[i].instruction[this.language];
      const requestData = { warning: instruction, language: this.language };
      const resp = await this.generalService
        .getTextAudio(requestData)
        .toPromise();
      const datas = resp['data'];
      let binary = convertDataURIToBinary(datas);
      let blob = new Blob([binary], { type: 'audio/ogg' });
      const blobUrl = URL.createObjectURL(blob);
      this.audioInstructionArray.push(blobUrl);
    }
    if (this.exercise.set_break?.active && this.exercise.set_break.duration > 0) {
      const instruction = exercise.set_break.instruction[this.language];
      const requestData = { warning: instruction, language: this.language };
      const resp = await this.generalService.getTextAudio(requestData).toPromise();
      const binary = convertDataURIToBinary(resp['data']);
      this.setBreakAudioUrl = URL.createObjectURL(new Blob([binary], { type: 'audio/ogg' }));
    }
  }

  isCheckpointMarginNeeded() {
    if (
      this.checkpointImage === 'victory_pose' ||
      this.checkpointImage === 'left_stretch' ||
      this.checkpointImage === 'right_stretch' ||
      this.checkpointImage === 'neutral_stretch'
    ) {
      this.checkpointImageMargin = true;
    }
    this.checkpointImageMargin = false;
  }




  // continueExercise() {
  //   const { handleImage } = this.getContext();
  //   handleImage(this.uploadImage);
  // }


}
