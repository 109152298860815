import { interval, of } from 'rxjs';
import { take, mergeMap, delay, repeat } from 'rxjs/operators';
import { TreatmentSummary } from '@schemas/exercise.model';
import { DateTime } from 'luxon';

const REGION = 'de-De';
const TIME_FORMAT = '2-digit';
export const delayX = (ms: number /*number of milliseconds*/, cb: Function) =>
  of(1)
    .pipe(delay(ms))
    .subscribe((v) => cb(v));
export const msdelay = (ms: number) => {
  return new Promise((res, rej) => {
    setTimeout(() => {
      res(true);
    }, ms);
  });
};
export const secondsCounter = (
  n: number,
  onEachStepCb: Function | null,
  onLastStepCb: Function
) =>
  interval(1000)
    .pipe(take(1), repeat(n))
    .pipe(mergeMap((v, i) => of(n - (i + 1))))
    .subscribe((v) => {
      if (onEachStepCb && v > 0 && v < n) {
        onEachStepCb(v);
      } else if (v === 0) {
        onLastStepCb(v);
      }
    });
export default class BreathMentHelper {
  static removeItemByIndex(items: [] | {}[], index: number) {
    return items.splice(index, 1);
  }

  static clone(item: any) {
    return Object.assign({}, item);
  }

  static currentDate() {
    return new Date().toLocaleDateString(REGION);
  }

  static currentTime() {
    return new Date().toLocaleTimeString([REGION], {
      hour: TIME_FORMAT,
      minute: TIME_FORMAT,
    });
  }
}
export const tryConvertBirthdateStringToDate = (str: string): Date => {
  try {

    const seperator = (str.indexOf('-') > -1) ? '-' : '.';
    const [day, month, year] = str.split(seperator).map(p => parseInt(p));
    if (day > year) {
      return new Date(day, month - 1, year)
    }
    return new Date(year, month - 1, day);
  } catch (error) { }
};
export const getDateInString = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const result: string = day + '.' + month + '.' + year;
  return result;
};

export const getDateFromString = (date: string) => {
  const stringDay = date.split('.')[0];
  const stringMonth = date.split('.')[1];
  const stringYear = date.split('.')[2];

  const newDate = new Date(stringYear + '/' + stringMonth + '/' + stringDay);
  let currentTime = new Date();
  newDate.setHours(0, 0, 0, 0);
  currentTime.setHours(0, 0, 0, 0);

  return newDate;
};

export const getFirstWeekday = (date: Date) => {
  date = new Date(date);
  let day = date.getDay(),
    diff = date.getDate() - day;
  // adjust when day is sunday
  return new Date(date.setDate(diff));
};
export const capitalizeFirstLetter = (string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const buildCalendarEvents = (
  exercise: any,
  locale: string,
  viewDate: Date,
  remainingPreviousExercises: any[],
  events: any[],
  todayAllExercises: any[],
  todayExercises: any[],
  isProgramDeleted: boolean = false
) => {
  for (const assigned of exercise.assigned_dates) {
    const stringDay = assigned.date.split('.')[0];
    const stringMonth = assigned.date.split('.')[1];
    const stringYear = assigned.date.split('.')[2];

    const date = new Date(stringYear + '/' + stringMonth + '/' + stringDay);
    let currentTime = new Date();
    date.setHours(0, 0, 0, 0);
    currentTime.setHours(0, 0, 0, 0);
    let color = colors.yellow;
    let performed: boolean;

    if (date <= currentTime) {
      if (assigned.performed) {
        color = colors.green;
        performed = true;
      } else {
        if (date < currentTime) {
          color = colors.red;
          performed = false;
        }
      }
    }

    let tempEventTitle: string;

    if (exercise.display_name) {
      if (exercise.display_name[locale]) {
        tempEventTitle = exercise.display_name[locale];
      } else {
        tempEventTitle = exercise.display_name;
      }
    } else {
      tempEventTitle = exercise.name;
    }

    const exerciseEvent = {
      start: date,
      title: tempEventTitle,
      color: color,
      allDay: true,
      id: exercise.exercise_id,
      performed: performed,
      meta: {
        program_id: exercise.program_id,
        isProgramDeleted,
        isExerciseDeleted: exercise.deleted,
      },
    };
    events.push(exerciseEvent);

    const day = viewDate.getDate();
    const month = viewDate.getMonth() + 1;
    const year = viewDate.getFullYear();

    const stringDate: string = day + '.' + month + '.' + year;
    const previousDay: number = day - 1;
    const previousDate: string = previousDay + '.' + month + '.' + year;

    if (assigned.date === stringDate) {
      todayAllExercises.push({
        exercise: exercise,
        performed: assigned.performed,
        date: stringDate,
      });
    }
    if (assigned.date === stringDate && !assigned.performed) {
      todayExercises.push({
        exercise: exercise,
        performed: assigned.performed,
        date: stringDate,
      });
    } else if (!assigned.performed && assigned.date === previousDate) {
      remainingPreviousExercises.push({
        exercise: exercise,
        performed: assigned.performed,
        date: previousDate,
      });
    }
  }
};

export const calculateAge = (birthdate) => {
  const date = birthdate.split('.');
  const birthdayDate = new Date(date[2], date[1] - 1, date[0]);

  var ageDifMs = Date.now() - birthdayDate.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};
// Colors
export const colors: any = {
  red: {
    primary: '#823335',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  green: {
    primary: '#2FC19A',
    secondary: '#77edc2',
  },
};

export const addAlldates = (
  assignedDates: any[],
  allExercisesDates: any[],
  todayExercises: any[],
  translate: any,
  activeTreatmentSummary?: TreatmentSummary
) => {
  const result: any = {};
  for (const assignDate of assignedDates) {
    if (!allExercisesDates.includes(assignDate.date)) {
      allExercisesDates.push(assignDate.date);
    }
  }

  result.donePercentage =
    ((activeTreatmentSummary.total_days - activeTreatmentSummary.days_left) /
      activeTreatmentSummary.total_days) *
    100;
  let translationString: string = 'patient.daysLeft';
  if (activeTreatmentSummary.days_left >= 0) {
    if (todayExercises.length > 0) {
      if (activeTreatmentSummary.days_left === 0) {
        translationString = 'patient.dayLeft';
        translate.get(translationString).subscribe((res: string) => {
          result.subtitle = '' + '1' + '\n' + res;
        });
      } else {
        translationString = 'patient.dayLeft';
        translate.get(translationString).subscribe((res: string) => {
          result.subtitle = '' + activeTreatmentSummary.days_left + '\n' + res;
        });
      }
    } else {
      if (activeTreatmentSummary.days_left === 0) {
        result.donePercentage = 100;
        translate.get('patient.done').subscribe((res: string) => {
          result.subtitle = res;
        });
      } else {
        translationString = 'patient.dayLeft';
        translate.get(translationString).subscribe((res: string) => {
          result.subtitle = '' + activeTreatmentSummary.days_left + '\n' + res;
        });
      }
    }
  } else {
    result.donePercentage = 100;

    translate.get('patient.done').subscribe((res: string) => {
      result.subtitle = res;
    });
  }
  return result;
};


export const convertDataURIToBinary = (dataURI) => {
  var BASE64_MARKER = ';base64,';
  var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  var base64 = dataURI.substring(base64Index);
  var raw = window.atob(base64);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}
export const getWarningKey = (warning: { optional: Array<string> | string | undefined, display_name?: any, message?: string, key?: string, unit?: string, value?: any }) => {
  if (warning.display_name) {
    warning.message = warning.display_name;
  }
  if (warning.key) return { key: warning.key }
  if (!warning.optional) {
    if (warning?.unit === 'boolean') {
      if (warning.value)
        return { key: warning.message }
      return { key: 'undefined-warning' }
    }
    if (warning?.unit === 'direction') {
      if (warning?.value == 1)
        return { key: warning.message + '+' + warning?.value?.toString() };
      if (warning?.value == -1)
        return { key: warning.message + warning?.value?.toString() };
    }
    return { key: warning.message };
  }
  if (!Array.isArray(warning.optional)) return { key: warning.message + warning.optional };

  if (warning.optional.length > 2) {
    const countLowerBody = warning.optional.filter((part: string) => ["left_knee", "right_knee", "left_ankle", "right_ankle"].indexOf(part) > -1).length;

    if (countLowerBody == warning.optional.length) {
      return { key: "keyPointMissing_lowerBody" }
    } else {
      if (countLowerBody == 0) {
        return { key: "keyPointMissing_upperBody" }
      } else {
        return { key: warning.message };
      }
    }
  } else {
    if (warning.optional.length == 1) {
      if (warning.optional.some(r => ['left_ankle', 'right_ankle', 'left_knee', 'right_knee'].indexOf(r) > -1)) {
        return { key: warning.message + '_' + warning.optional[0] + 'Backward' };
      }

      if (warning.optional.some(r => ['hip', 'elbow', 'knee', 'shoulder', 'right_elbow', 'left_elbow'].indexOf(r) > -1))
        return { key: warning.message };
    } else if (warning.optional.length == 2) {
      const bodyParts = ["ankle", "knee", "elbow", "shoulder"]
      for (const part of bodyParts) {
        if (warning.optional.sort().join(',') === [`left_${part}`, `right_${part}`].sort().join(',')) {
          return { key: warning.message + '_' + part + 'sBackward' };
        }
      }
    }
    if (warning.optional.some(r => ['nose', 'right_eye', 'left_eye', 'right_ear', 'left_ear'].indexOf(r) > -1)) {
      return { key: 'faceNotDetected' };
    } else {
      return { key: warning.message + '_' + warning.optional[0] };
    }

  }
}

const getRequiredQuestions = async (translate, values?) => {
  return [

    [
      {
        show: true,
        id: 'gender',
        type: 'dropdown',
        name: 'personal_information.gender',
        label: await translate.get('createPatientForm.personalInformationCard.salutation.title').toPromise(),
        value: values ? await translate.get('createPatientForm.personalInformationCard.salutation.' + values['personal_information']['gender']).toPromise() : await translate.get('generalTerms.select').toPromise(),
        defaultValue: await translate.get('generalTerms.select').toPromise(),
        items: [
          {
            label: await translate.get('createPatientForm.personalInformationCard.salutation.male').toPromise(),
            value: await translate.get('createPatientForm.personalInformationCard.salutation.male').toPromise(),
            id: 'male'
          },
          {
            label: await translate.get('createPatientForm.personalInformationCard.salutation.female').toPromise(),
            value: await translate.get('createPatientForm.personalInformationCard.salutation.female').toPromise(),
            id: 'female'
          },
          {
            label: await translate.get('createPatientForm.personalInformationCard.salutation.diverse').toPromise(),
            value: await translate.get('createPatientForm.personalInformationCard.salutation.diverse').toPromise(),
            id: 'diverse'
          }
        ],
        required: true
      },
      {
        show: true,
        id: 'personal_information.first_name',
        type: 'text',
        name: 'personal_information.first_name',
        label: await translate.get('generalTerms.first_name').toPromise(),
        required: true,
        value: values ? values['personal_information']['first_name'] : '',
        validator: (value) => {
          return is_valid_name(value)
        },
        errorMessage: await translate.get('errorMessages.onlyLetters').toPromise()
      },
      {
        show: true,
        id: 'personal_information.last_name',
        type: 'text',
        name: 'personal_information.last_name',
        label: await translate.get('generalTerms.last_name').toPromise(),
        value: values ? values['personal_information']['last_name'] : '',
        required: true,
        validator: (value) => {
          return is_valid_name(value)
        },
        errorMessage: await translate.get('errorMessages.onlyLetters').toPromise()
      },
    ],
    [
      {
        show: true,
        id: 'personal_information.phone',
        type: 'text',
        name: 'personal_information.phone',
        label: await translate.get('createPatientForm.personalInformationCard.phone').toPromise(),
        placeholder: '+4912345678900',
        required: true,
        value: values ? values['personal_information']['phone'] : '',
        validator: (value) => {
          return new RegExp('[+][0-9]{9,15}$').test(value)
        },
        errorMessage: await translate.get('errorMessages.validPhone').toPromise()
      },
      {
        show: true,
        type: 'text',
        id: 'personal_information.email',
        name: 'personal_information.email',
        label: await translate.get('createPatientForm.personalInformationCard.emailAddress').toPromise(),
        value: values ? values['personal_information']['email'] : '',
        required: true,
        validator: (value) => {
          const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return emailRegex.test(value)
        },
        errorMessage: await translate.get('errorMessages.validEmail').toPromise()
      },
    ],
    [
      {
        show: true,
        id: 'personal_information.dob',
        type: 'date',
        name: 'personal_information.dob',
        label: await translate.get('createPatientForm.personalInformationCard.birthdate').toPromise(),
        required: true,
        value: values ? values['personal_information']['age'] : '',
        validator: (value) => {

          const dob = DateTime.fromFormat(value, 'yyyy-MM-dd')
          const age = DateTime.now().diff(dob, 'years').years;
          return age >= 18.0 && age < 99.0
        },
        errorMessage: await translate.get('errorMessages.ageRange').toPromise()
      },
    ],
    [
      {
        show: true,
        id: 'personal_information.contact_reason',
        type: 'text',
        name: 'personal_information.contact_reason',
        value: values ? values['personal_information']['contact_reason'] : '',
        label: await translate.get('createPatientForm.personalInformationCard.contactReason').toPromise(),
        required: true
      },
    ],
    [
      {
        show: true,
        id: 'personal_information.reason_explanation',
        type: 'textarea',
        name: 'personal_information.reason_explanation',
        value: values ? values['personal_information']['reason_explanation'] : '',
        label: await translate.get('createPatientForm.personalInformationCard.reasonExplanation').toPromise(),
        required: true
      },
    ],
    [
      {
        show: true,
        id: 'personal_information.health_insurance',
        type: 'text',
        name: 'personal_information.health_insurance',
        value: values ? values['personal_information']['health_insurance'] : '',
        label: await translate.get('createPatientForm.personalInformationCard.healthInsurance').toPromise(),
        required: true
      },
    ],
  ]
}
export const transformOnboardingFormData = async (cards, locale, translate, values?) => {
  const requiredQ = await getRequiredQuestions(translate, values);
  const handleQuestionsList = (qList, index) => {

    let linkedQuestions;
    const questions: any = [];
    let i = 0;
    for (const question of qList) {
      const { values: qLabel, ...q } = question;

      linkedQuestions = [];

      const val = values ? values.other_info[q.id] : undefined;
      q.value = val;
      if (q.type == 'textbox') {
        q.type = 'text';
        delete q.options;
        q.show = true
        questions.push([{ ...q, label: qLabel[locale] ?? qLabel['en'], name: q?.id }])
      }
      if (q.type == 'date') {
        delete q.options;
        q.show = true
        questions.push([{ ...q, label: qLabel[locale] ?? qLabel['en'], name: q?.id }])
      }
      if (q.type == 'radio') {
        const label = qLabel[locale] ?? qLabel['en'];
        q.show = true
        //delete q.label
        const name = q.id;//this.replaceAllSpacesAndSpecialChars(values['en'],'')
        let lqc = 0;
        q.options = q.options.map(({ values: optLabel, id: optId, ...opt }) => {
          if (opt.followUp || opt.followUpItems?.length > 0) {
            // if (opt.followUp) { 
            //   linkedQuestion = handleQuestionsList([opt.followUpItem], index)[0];
            //   linkedQuestion[0].show = q.value == optId;
            //   linkedQuestion[0].required = linkedQuestion[0].show == true
            //   linkedQuestions.push(linkedQuestion);
            // }

            let subquestionsList = handleQuestionsList(opt.followUpItems, index);
            subquestionsList = subquestionsList.map(lq => {
              const show = q.value == optId
              return { ...lq[0], show, required: show };
            })



            opt.child = { questionIndex: (index == 0 ? 6 : 0) + (questions.length + 1), linkedValue: optId, parentId: q.id };
            opt.child.linkedQuestionsCount = subquestionsList?.length
            opt.child.questionIndex += lqc;
            lqc += subquestionsList.length;
            linkedQuestions = [...linkedQuestions, ...subquestionsList]
          }
          return { ...opt, name, label: optLabel[locale] ?? optLabel['en'], checked: false, value: optId, id: optId }
        })
        questions.push([{ ...q, label, name, show: true }])

        if (linkedQuestions?.length > 0) {
          for (const lq of linkedQuestions)
            questions.push([lq])
        }
        //console.log({questions})
      }
      if (q.type == 'number') {
        const label = qLabel[locale] ?? qLabel['en'];
        q.show = true
        //const name = this.replaceAllSpacesAndSpecialChars(values['en'],'')
        questions.push([{ ...q, name: q.id, label, }])
      }
      if (q.type == 'checkbox') {
        q.show = true
        const label = qLabel[locale] ?? qLabel['en'];
        //delete q.label
        try {
          q.options = q.options.map(({ values: optLabel, ...opt }, id) => {
            //const name = this.replaceAllSpacesAndSpecialChars(values['en'],'')+Math.random()

            const founded = q.value ? q.value.find(v => v.name == opt.id) : undefined;
            return { label: optLabel[locale] ?? optLabel['en'], checked: founded?.value ?? false, value: founded?.value ?? false, name: opt.id, id: opt.id }
          })
        } catch (error) {

        }
        delete q.label;
        questions.push([{ ...q, name: q?.id, label }])
      }
      i++;
    }


    //cards[index].questions = questions
    return questions;
  }
  cards.forEach((card, index) => {
    cards[index].questions = handleQuestionsList(card.questions, index)
  })
  cards[0].questions = [...requiredQ, ...cards[0].questions];

  return cards
};
export const is_valid_name = (name) => {
  // Check if the name is empty.
  if (!name) {
    return false;
  }

  // Check if the first character is a letter or underscore.
  if (!/^[a-zA-Z_ÇçĞğÖöŞşÜüİıàáâäãåąčćęèéêëėįìíîïłńòóôõøùúûųūÿýżźñčšžæÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÕØÙÚÛŲŪŸÝŻŹÑßŒÆČŠŽ∂ð]/.test(name)) {
    return false;
  }

  // Check if the name contains only letters, numbers, underscores, and other special characters.
  if (!/^[a-zA-ZÇçĞğÖöŞşÜüİıàáâäãåąčćęèéêëėįìíîïłńòóôõøùúûųūÿýżźñčšžæÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÕØÙÚÛŲŪŸÝŻŹÑßŒÆČŠŽ∂ð'_ -]*$/.test(name)) {
    return false;
  }

  // Check if the name is a reserved keyword.
  const reserved_keywords = new Set([
    "break", "case", "catch", "class", "const", "continue", "debugger", "default",
    "delete", "do", "else", "enum", "export", "extends", "finally", "for", "function",
    "if", "implements", "import", "in", "instanceof", "interface", "let", "return",
    "super", "switch", "this", "throw", "try", "typeof", "var", "void", "while",
    "with", "yield"
  ]);
  if (reserved_keywords.has(name.toLowerCase())) {
    return false;
  }

  return true;
}

export function closeTheCamera() {
  try {
    const tracks = (this.video.nativeElement.srcObject as MediaStream).getTracks();
    tracks.forEach(track => track.stop());
    this.video.nativeElement.srcObject = null;
  } catch (error) {

  }
}
// export const convertUTCTimeToLocalTimeZone = (dateStr: string) => {
//   if (!dateStr) return 'N/A'
//   //const dateStr = '2024-08-21 14:09:46.465079';
//   const date = new Date(dateStr + ' UTC'); // Append ' UTC' to treat the date as UTC
//   const formatter = new Intl.DateTimeFormat('en-US', {
//     year: 'numeric',
//     day: '2-digit',
//     month: '2-digit',
//     hour: '2-digit',
//     minute: '2-digit',
//     // second: '2-digit',
//     hour12: false,
//     //timeZoneName: 'short',
    
//   });
//   let formattedDate = formatter.format(date);

//   // Reformat the string to "year.month.day 24hours:minutes"
//   formattedDate = formattedDate.replace(',', ' '); // Remove comma for formatting
//   formattedDate = formattedDate.replace(/\//g, '.'); // Replace slashes with dots

  
//   return formattedDate;
  
// }


export const convertUTCTimeToLocalTimeZone = (dateStr) => {
  // Convert input string to a Date object, assuming it's in UTC
  if(!dateStr) return 'N/A'
  const date = new Date(dateStr + ' UTC');
  
  // Define the desired format for output
  const options:any = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    //timeZone: 'UTC',
    hour: '2-digit',
    minute: '2-digit',
    // second: '2-digit',
    hour12: false,
  };

  // Create a formatter for the desired format
  const formatter = new Intl.DateTimeFormat('en-GB', options); // Using 'en-GB' for DD/MM/YYYY

  // Format the date
  let formattedDate = formatter.format(date);
  
  // Reformat to "DD.MM.YYYY"
  formattedDate = formattedDate.replace(/\//g, '.');
  formattedDate = formattedDate.replace(',', ' '); // Remove comma for formatting
  return formattedDate;
};